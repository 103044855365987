import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCelebrities, fetchMoreCelebrities } from '../actions';
import { getCelebrityQueryResult } from '../reducers';

export default function useCelebrityQuery(query) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCelebrities(query));
  }, [query, dispatch]);

  const selector = useCallback(
    state => getCelebrityQueryResult(state, query.key),
    [query.key],
  );

  function more() {
    dispatch(fetchMoreCelebrities(query));
  }

  return { ...useSelector(selector), more };
}
