import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchFields, addField, deleteField } from '../../actions';
import { getFields } from '../../reducers';
import Icon from '../Icon';
import DeleteDialog from '../DeleteDialog';

const initialState = { key: '', value: '' };

export default function FieldList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFields());
  }, [dispatch]);

  const fields = useSelector(getFields);

  const [state, setState] = useState({ key: '', value: '' });

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(addField(state.key, state.value)).then(() => {
      setState(initialState);
    });
  }

  function handleEditClick(key) {
    setState({ key, value: fields[key] });
  }

  function handleDelete(key) {
    dispatch(deleteField(key));
  }

  function handleChange(event) {
    const nextState = { ...state };
    nextState[event.target.name] = event.target.value;
    setState(nextState);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  function openDeleteDialog(key) {
    setSelectedField(key);
    setIsOpen(true);
  }

  function toggleDeleteDialog() {
    setIsOpen(!isOpen);
  }

  const disabled = state.key === '' || state.value === '';
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col">
          <form
            className="form-inline"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <label className="sr-only" htmlFor="key">
              Field Key
            </label>
            <input
              className="form-control mr-2"
              id="key"
              name="key"
              placeholder="Field Key"
              value={state.key}
              onChange={handleChange}
            />
            <label className="sr-only" htmlFor="value">
              Field Value
            </label>
            <input
              className="form-control mr-2"
              id="value"
              name="value"
              placeholder="Field Value"
              value={state.value}
              onChange={handleChange}
            />
            <button
              className="btn btn-primary"
              type="submit"
              disabled={disabled}
            >
              {Object.keys(fields).includes(state.key)
                ? 'Edit Field'
                : 'Add Field'}
            </button>
          </form>
        </div>
      </div>
      <Fields
        fields={fields}
        onEditClick={handleEditClick}
        onDeleteClick={openDeleteDialog}
      />
      <DeleteDialog
        isOpen={isOpen}
        onDelete={() => handleDelete(selectedField)}
        onToggle={toggleDeleteDialog}
        itemName={selectedField && `${fields[selectedField]}`}
      />
    </div>
  );
}

function Fields({ fields, onEditClick, onDeleteClick }) {
  return (
    <div className="table-responsive-sm mt-3">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Key</th>
            <th scope="col">Value</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(fields).map((key, idx) => (
            <tr key={key}>
              <td>{idx + 1}</td>
              <td>{key}</td>
              <td>{fields[key]}</td>
              <td>
                <button
                  className="btn"
                  type="button"
                  onClick={() => onEditClick(key)}
                >
                  <Icon
                    icon="edit"
                    fixedWidth
                    className="text-dark"
                    size="lg"
                  />
                </button>
                <button
                  className="btn"
                  type="button"
                  onClick={() => onDeleteClick(key)}
                >
                  <Icon
                    icon="trash-alt"
                    fixedWidth
                    className="text-danger"
                    size="lg"
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
