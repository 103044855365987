import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { get } from 'lodash';

import { useCelebrityQuery } from '../hooks';
import CelebrityImage from './Celebrity/CelebrityImage';
import {
  setCurrentField,
  setCurrentCountry,
  vote,
  fetchCelebrities,
} from '../actions';
import {
  getCurrentField,
  getVotingCelebrityIds,
  getProfileIsFetching,
  getCurrentCountry,
  getVoteableCountries,
  getVoteableFields,
} from '../reducers';
import withEmailVerification from './withEmailVerification';
import { celebrityProfileUrl, isVowel } from '../helpers';
import CelebrityProfile from './Celebrity/CelebrityProfile';
import CelebrityCompare from './Celebrity/CelebrityCompare';
import Icon from './Icon';

function Vote() {
  const field = useSelector(getCurrentField);
  const country = useSelector(getCurrentCountry);
  const countries = useSelector(getVoteableCountries);
  const fields = useSelector(getVoteableFields);
  const fieldsMap = useMemo(
    () =>
      fields.reduce((obj, curr) => {
        obj[curr.value] = curr.label;
        return obj;
      }, {}),
    [fields]
  );
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (
      countries.length !== 0 &&
      !countries.map(c => c.value).includes(country)
    ) {
      dispatch(setCurrentCountry('INTL'));
    }
  }, [country, countries, dispatch]);

  useEffect(() => {
    if (fields.length !== 0 && !fields.map(f => f.value).includes(field)) {
      dispatch(setCurrentField(fields[0].value));
    }
  }, [field, fields, dispatch]);

  useEffect(() => {
    dispatch(vote(field, country));
  }, [field, country, dispatch]);

  const celebrityIds = useSelector(getVotingCelebrityIds);
  const isProfileFetching = useSelector(getProfileIsFetching);

  const query = useMemo(
    () => ({
      ids: celebrityIds,
      key: 'vote',
    }),
    [celebrityIds]
  );
  useEffect(() => {
    dispatch(fetchCelebrities(query));
  }, [query, dispatch]);

  const { celebrities, isFetching, error } = useCelebrityQuery(query);

  let celebrity1, celebrity2;
  if (celebrities.length === 2) {
    celebrity1 = celebrities[0];
    celebrity2 = celebrities[1];
  }

  function handleVote(event, winner) {
    event.preventDefault();
    if (!celebrity1 || !celebrity2) {
      return;
    }

    dispatch(vote(field, country, winner)).catch(error => {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    });
  }

  function handleFieldChange(event) {
    dispatch(setCurrentField(event.target.value));
  }

  function handleCountryChange(event) {
    dispatch(setCurrentCountry(event.target.value));
  }

  function handleSkip(event) {
    event.preventDefault();
    dispatch(vote(field, country));
  }

  const imageContainerRef1 = useRef(null);
  const imageContainerRef2 = useRef(null);

  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(0);

  function toggleModal() {
    setModal(!modal);
  }

  function openModal(event, selected) {
    event && event.preventDefault();
    setSelected(selected);
    toggleModal();
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 pr-sm-2">
          <form className="form-inline d-flex justify-content-end">
            <label className="sr-only" htmlFor="country">
              Country
            </label>
            <select
              className="form-control mt-2"
              id="country"
              name="country"
              onChange={handleCountryChange}
              value={country}
            >
              {countries.map(country => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </select>
          </form>
        </div>
        <div className="col-sm-6 pl-sm-2">
          <form className="form-inline d-flex justify-content-start">
            <label className="sr-only" htmlFor="field">
              Field
            </label>
            <select
              className="form-control mt-2"
              id="field"
              name="field"
              onChange={handleFieldChange}
              value={field}
            >
              {fields.map(field => (
                <option key={field.value} value={field.value}>
                  {field.label}
                </option>
              ))}
            </select>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <h1
            className="m-2 text-primary"
            style={{ fontFamily: 'Marck Script' }}
          >
            Who do you like more as{' '}
            {`${isVowel(get(fieldsMap, field, ' ')[0]) ? 'an' : 'a'}`}{' '}
            <u>{get(fieldsMap, field, '').toLowerCase()}</u>? (click the photo)
          </h1>
        </div>
      </div>
      {isFetching || isProfileFetching ? (
        <div className="row">
          <div className="col text-center">
            <div
              className="spinner-grow spinner-grow-lg text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : error ? (
        <div className="row">
          <div className="col text-center">
            <span className="text-danger">{error.message}</span>
          </div>
        </div>
      ) : !celebrity1 || !celebrity2 ? (
        <div className="row">
          <div className="col text-center">
            <span className="text-primary">
              Your internet connection is very slow. Please wait a few seconds.
              After the wait, if you can't see the celebrities, please refresh
              the page or select another country and field.
            </span>
            <span className="text-primary"></span>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <div
              className="col-6 col-lg-4 offset-lg-2 d-flex justify-content-end pr-sm-2"
              ref={imageContainerRef1}
            >
              <a
                href="#left"
                onClick={event => handleVote(event, celebrityIds[0])}
              >
                <CelebrityImage
                  celebrity={celebrity1}
                  container={imageContainerRef1.current}
                />
              </a>
            </div>
            <div
              className="col-6 col-lg-4 d-flex justify-content-start pl-sm-2"
              ref={imageContainerRef2}
            >
              <a
                href="#right"
                onClick={event => handleVote(event, celebrityIds[1])}
              >
                <CelebrityImage
                  celebrity={celebrity2}
                  container={imageContainerRef2.current}
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div
              className="col-6 col-lg-4 offset-lg-2 d-flex justify-content-end pr-sm-2"
              ref={imageContainerRef1}
            >
              <a
                href={celebrityProfileUrl(celebrity1.id, celebrity1)}
                onClick={e => openModal(e, 1)}
              >
                {celebrity1.firstname} {celebrity1.lastname}
                <Icon icon="external-link-alt" className="ml-1" />
              </a>
            </div>
            <div
              className="col-6 col-lg-4 d-flex justify-content-start pl-sm-2"
              ref={imageContainerRef2}
            >
              <a
                href={celebrityProfileUrl(celebrity2.id, celebrity2)}
                onClick={e => openModal(e, 2)}
              >
                {celebrity2.firstname} {celebrity2.lastname}
                <Icon icon="external-link-alt" className="ml-1" />
              </a>
            </div>
          </div>
          <Modal
            isOpen={modal}
            toggle={toggleModal}
            size="xl"
            scrollable={true}
          >
            <ModalBody>
              {selected === 0 ? (
                <CelebrityCompare
                  celebId1={celebrity1.id}
                  celebId2={celebrity2.id}
                />
              ) : (
                <CelebrityProfile
                  celebrityId={selected === 1 ? celebrity1.id : celebrity2.id}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
      {!isFetching && !isProfileFetching && !!celebrity1 && !!celebrity2 && (
        <div className="row">
          <div className="col-sm-6 pr-sm-2">
            <form className="form-inline d-flex justify-content-end">
              <button
                className="form-control mt-2"
                style={{ minWidth: '100px' }}
                onClick={e => openModal(e, 0)}
              >
                Compare
              </button>
            </form>
          </div>
          <div className="col-sm-6 pl-sm-2">
            <form className="form-inline d-flex justify-content-start">
              <button
                className="form-control mt-2"
                style={{ minWidth: '100px' }}
                onClick={handleSkip}
              >
                Skip
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default withEmailVerification(Vote);
