import React, { useReducer, useEffect, useMemo } from 'react';
import { useHtmlElementSize } from '../../hooks';

const INITIAL_STATE = {
  photos: {},
  loading: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      };
    case 'success':
      return {
        ...state,
        photos: { ...state.photos, [action.key]: action.url },
        loading: false,
      };
    case 'error':
      return {
        ...state,
        loading: false,
      };
    default:
      throw new Error();
  }
}

export default function CelebrityImage({ celebrity, container, ...props }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const photo = useMemo(() => (celebrity ? celebrity.photo : null), [
    celebrity,
  ]);
  const photoUrl = useMemo(() => (celebrity ? celebrity.photoUrl : null), [
    celebrity,
  ]);
  useEffect(() => {
    dispatch({ type: 'loading' });
    if (photo instanceof File) {
      const reader = new FileReader();
      reader.onload = event => {
        dispatch({
          type: 'success',
          key: photo.name,
          url: event.target.result,
        });
      };
      reader.onerror = event => {
        dispatch({ type: 'error' });
        reader.abort();
        if (process.env.NODE_ENV === 'development') {
          console.error(event);
        }
      };
      reader.readAsDataURL(photo);
    } else if (photoUrl) {
      dispatch({ type: 'success', key: photoUrl, url: photoUrl });
    } else if (typeof photo === 'string') {
      // const storage = firebase.storage().ref();
      // storage
      //   .child(photo)
      //   .getDownloadURL()
      //   .then(url => {
      //     dispatch({ type: 'success', key: photo, url });
      //   })
      //   .catch(error => {
      //     dispatch({ type: 'error' });
      //     if (process.env.NODE_ENV === 'development') {
      //       console.error(error.message);
      //     }
      //   });
    } else {
      dispatch({ type: 'error' });
    }
  }, [photo, photoUrl]);

  let style = { ...props.style };
  const size = useHtmlElementSize(container);
  if (props.width) {
    style = {
      width: `${props.width}px`,
      height: `${(props.width * 4) / 3}px`,
      ...style,
    };
  } else if (size[0]) {
    style = {
      width: `${size[0]}px`,
      height: `${(size[0] * 4) / 3}px`,
      ...style,
    };
  }

  const { photos, loading } = state;
  const key = loading
    ? null
    : photo instanceof File
    ? photo.name
    : photoUrl
    ? photoUrl
    : photo;
  return (
    <div
      className={`celebrity-profile-image${
        !photos[key] ? ' border border-primary' : ''
      }`}
      style={style}
    >
      {photos[key] && (
        <img
          className={`img-fluid${props.className ? ` ${props.className}` : ''}`}
          style={{
            objectFit: 'cover',
            ...style,
          }}
          src={photos[key]}
          alt={celebrity.firstname + ' ' + celebrity.lastname}
        />
      )}
      {loading ? (
        <div className="celebrity-image-help p-1">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        props.helpText && (
          <div
            className="celebrity-image-help rounded p-1"
            style={{
              display: photos[key] ? 'none' : 'block',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
          >
            {props.helpText}
          </div>
        )
      )}
    </div>
  );
}
