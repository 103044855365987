import { useMemo } from 'react';

import { useCelebrityQuery } from '.';
import * as STATUS from '../constants/celebrityStatus';
import { getScoreLabel, getIsPublishableLabel } from '../helpers';

export default function useCelebrityByScore(field, year, country = 'INTL') {
  const isNatl = country !== 'INTL';
  const orderBy = useMemo(() => getScoreLabel(field, year, isNatl), [
    field,
    year,
    isNatl,
  ]);
  const isPublishable = useMemo(() => getIsPublishableLabel(field, isNatl), [
    field,
    isNatl,
  ]);
  const key = useMemo(() => `${field}CelebritiesOrderedByScore${year}`, [
    field,
    year,
  ]);
  const query = useMemo(() => {
    const where = [
      ['status', '==', STATUS.APPROVED],
      ['fields', 'array-contains', field],
      [isPublishable, '==', true],
    ];
    if (isNatl) {
      where.push(['country', '==', country]);
    }
    return {
      where,
      orderBy: [orderBy, 'desc'],
      limit: 5,
      key,
    };
  }, [field, country, isNatl, orderBy, key, isPublishable]);
  return { ...useCelebrityQuery(query), orderBy };
}
