import React, { useMemo } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/celebrityStatus';
import { useRouter, useCelebrityQuery, useFormInput } from '../../hooks';
import { getCelebritiesByKeyword } from '../../reducers';
import Icon from '../Icon';

const filters = {
  all: 'All',
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
  borntoday: 'Born Today',
};

export default function CelebrityList() {
  const { match } = useRouter();
  const filter = match.params.filter ? match.params.filter : 'all';
  const query = useMemo(() => {
    const key = `${filter}Celebrities`;
    let query;
    if (filter === 'borntoday') {
      const today = moment().format('MM-DD');
      query = {
        where: ['birthday', '==', today],
        key,
      };
    } else {
      query = {
        orderBy: ['createdAt', 'desc'],
        limit: 20,
        key,
      };
      if (filter !== 'all') {
        let status;
        switch (filter) {
          case 'pending':
            status = STATUS.PENDING;
            break;
          case 'approved':
            status = STATUS.APPROVED;
            break;
          case 'rejected':
            status = STATUS.REJECTED;
            break;
          default:
            break;
        }
        query.where = ['status', '==', status];
      }
    }
    return query;
  }, [filter]);
  const { isFetching, error, more, endOfResult } = useCelebrityQuery(query);

  const keyword = useFormInput('');
  const celebrities = useSelector(state =>
    getCelebritiesByKeyword(state, keyword.value, query.key)
  );

  return (
    <div className="container">
      {renderFilterLinks(filters)}
      {!isFetching && !celebrities.length ? (
        <div className="row">
          <div className="col-12 mt-2 text-center">
            <span className="text-primary">No celebrity.</span>
          </div>
        </div>
      ) : (
        <div className="table-responsive-sm mt-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">
                  <form className="form-inline ml-n2" autoComplete="off">
                    <input
                      className="form-control"
                      name="keyword"
                      {...keyword}
                      placeholder="Filter by name"
                    />
                  </form>
                </th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>{renderCelebrities(celebrities)}</tbody>
          </table>
        </div>
      )}
      {!isFetching && error && (
        <div className="row">
          <div className="col-12 mt-2 text-center">
            <span className="text-danger">{error.message}</span>
          </div>
        </div>
      )}
      {!!celebrities.length && endOfResult && (
        <div className="row">
          <div className="col-12 mt-2 text-center">
            <span className="text-primary">No more celebrity.</span>
          </div>
        </div>
      )}
      {!endOfResult && (
        <div className="row">
          <div className="col-12 mt-2 text-center">
            <button className="btn btn-primary" type="button" onClick={more}>
              {isFetching ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                'More'
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function renderCelebrities(celebrities) {
  return celebrities.map((celebrity, idx) => (
    <tr key={celebrity.id}>
      <td>{idx + 1}</td>
      <td>
        {celebrity.firstname} {celebrity.lastname}
      </td>
      <td>
        <Link
          className="btn"
          to={`${ROUTES.ADMIN_CELEBRITY_DETAIL}/${celebrity.id}`}
        >
          <Icon
            icon="info-circle"
            fixedWidth
            size="lg"
            className="text-primary"
          />
        </Link>
      </td>
    </tr>
  ));
}

function renderFilterLinks(filters) {
  return (
    <ul className="nav nav-pills mt-2">
      {Object.keys(filters).map(filter => (
        <li key={filter} className="nav-item">
          <NavLink
            exact={true}
            to={`${ROUTES.ADMIN_CELEBRITY_LIST}/${
              filter === 'all' ? '' : filter
            }`}
            className="nav-link"
            activeClassName="active"
          >
            {filters[filter]}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
