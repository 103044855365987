import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import { firebaseConfig } from '../config';

export default class FirebaseAPI {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.FieldValue = app.firestore.FieldValue;
    this.Timestamp = app.firestore.Timestamp;

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.functions = app.functions();

    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  signUp = user =>
    this.auth
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(authUser =>
        this.user(authUser.user.uid).set(
          {
            email: user.email,
            displayName: user.displayName,
          },
          { merge: true }
        )
      )
      .then(() => this.sendEmailVerification())
      .then(() => this.auth.currentUser);

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  signInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  signInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  signOut = () => this.auth.signOut();

  sendPasswordResetEmail = email => this.auth.sendPasswordResetEmail(email);

  sendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_EMAIL_VERIFICATION_REDIRECT,
    });

  updatePassword = password => this.auth.currentUser.updatePassword(password);

  deleteAccount = async () => {
    await this.auth.currentUser.delete();
  };

  onAuthStateChanged = listener =>
    this.auth.onAuthStateChanged(authUser => {
      if (!authUser) {
        listener(authUser, {});
        return;
      }
      this.getUser(authUser.uid).then(user => {
        listener(authUser, user);
      });
    });

  getUser = uid =>
    this.user(uid)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) {
          return {};
        }
        const user = snapshot.data();
        if (!user.role) {
          user.role = 'user';
        }
        return user;
      });

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  updateProfile = data => {
    const authUser = this.auth.currentUser;
    const user = this.user(authUser.uid);
    return user.update(data);
  };

  celebrity = id => this.db.doc(`celebrities/${id}`);

  celebrities = () => this.db.collection('celebrities');

  fetchCelebrities = query => {
    let celebrities = this.celebrities();
    if (!query) {
      return celebrities.get();
    }
    const { where, orderBy, limit, startAt, startAfter, endAt } = query;
    if (Array.isArray(where) && where.length > 0) {
      if (Array.isArray(where[0])) {
        query.where.forEach(w => {
          celebrities = celebrities.where(...w);
        });
      } else {
        celebrities = celebrities.where(...where);
      }
    }
    if (orderBy) {
      if (Array.isArray(orderBy)) {
        if (Array.isArray(orderBy[0])) {
          orderBy.forEach(o => {
            celebrities = celebrities.orderBy(...o);
          });
        } else {
          celebrities = celebrities.orderBy(...orderBy);
        }
      } else {
        celebrities = celebrities.orderBy(orderBy);
      }
    }
    if (startAt) {
      celebrities = celebrities.startAt(startAt);
    }
    if (startAfter) {
      celebrities = celebrities.startAfter(startAfter);
    }
    if (endAt) {
      celebrities = celebrities.endAt(endAt);
    }
    if (limit) {
      celebrities = celebrities.limit(limit);
    }
    return celebrities.get();
  };

  vote = (field, country, winner) => {
    const voteFunc = this.functions.httpsCallable('vote');
    return voteFunc({
      field,
      country,
      winner,
    });
  };

  createLevels = () => {
    const createLevelsFunc = this.functions.httpsCallable('createLevels');
    return createLevelsFunc({});
  };

  metadata = id => this.db.doc(`metadata/${id}`);

  fields = () => this.metadata('fields');

  addFields = fields => this.fields().set(fields, { merge: true });

  updateFields = fields => this.fields().update(fields);
}
