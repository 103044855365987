import React from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../hooks';
import { deleteAccount } from '../../actions';

export default function AccountDelete() {
  const { history } = useRouter();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  async function handleYes() {
    try {
      await dispatch(deleteAccount());
      history.push(ROUTES.HOME);
      addToast('Your account has been deleted successfully.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        addToast(error.message, {
          appearance: 'warning',
          autoDismiss: true,
        });
        history.push(`${ROUTES.SIGNIN}?redirect=${ROUTES.ACCOUNT_DELETE}`);
      } else {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }

  function handleNo() {
    history.goBack();
  }

  return (
    <form className="form-auth" autoComplete="off">
      <div className="text-center mb-4">
        <h1 className="h3 mb-3 font-weight-normal">Delete My Account</h1>
      </div>
      <div className="form-label-group">
        <p>
          This action cannot be reversable.
          <br />
          Are you sure to delete your account?
        </p>
      </div>
      <button
        className="btn btn-outline-danger btn-block"
        type="button"
        onClick={handleYes}
      >
        Yes, I am sure
      </button>
      <button
        className="btn btn-outline-primary btn-block"
        type="button"
        onClick={handleNo}
      >
        Cancel
      </button>
    </form>
  );
}

export function AccountDeleteButton() {
  const { history } = useRouter();

  function handleClick(event) {
    history.push(ROUTES.ACCOUNT_DELETE);
  }

  return (
    <div className="mb-2">
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={handleClick}
      >
        Delete My Account
      </button>
    </div>
  );
}
