import { get } from 'lodash';

const INITIAL_STATE = { isFetching: false, error: null };

const profile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...INITIAL_STATE,
        ...action.profile,
      };
    case 'UPDATE_PROFILE_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case 'UPDATE_PROFILE_SUCCESS':
      return {
        ...state,
        ...action.profile,
        isFetching: false,
      };
    case 'UPDATE_PROFILE_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case 'VOTE_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case 'VOTE_SUCCESS':
      return action.result && action.result.data
        ? {
            ...state,
            votingCelebrityIds: action.result.data,
            isFetching: false,
            error: null,
          }
        : state;
    case 'VOTE_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    // case 'ADD_FAVORITE_SUCCESS': {
    //   return {
    //     ...state,
    //     favorites: action.favorites,
    //   };
    // }
    // case 'REMOVE_FAVORITE_SUCCESS': {
    //   return {
    //     ...state,
    //     favorites: action.favorites,
    //   };
    // }
    default:
      return state;
  }
};

export default profile;

export const getProfile = state => state;
export const getProfileIsFetching = state => state.isFetching;
export const getFavorites = state => get(state, 'favorites', []);
export const getVotingCelebrityIds = state =>
  get(state, 'votingCelebrityIds', null);
