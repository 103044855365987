import { useMemo } from 'react';

import { useCelebrityQuery } from '.';
import * as STATUS from '../constants/celebrityStatus';
import { getRatingLabel, getIsPublishableLabel } from '../helpers';

export default function useCelebrityByRating(field, country = 'INTL') {
  const isNatl = country !== 'INTL';
  const [orderBy, isPublishable, key] = useMemo(
    () => [
      getRatingLabel(field, isNatl),
      getIsPublishableLabel(field, isNatl),
      `${field}OrderedByRating`,
    ],
    [field, isNatl]
  );
  const query = useMemo(() => {
    const where = [
      ['status', '==', STATUS.APPROVED],
      ['fields', 'array-contains', field],
      [isPublishable, '==', true],
    ];
    if (isNatl) {
      where.push(['country', '==', country]);
    }
    return {
      where,
      orderBy: [orderBy, 'desc'],
      limit: 5,
      key,
    };
  }, [field, country, isNatl, orderBy, key, isPublishable]);
  return { ...useCelebrityQuery(query), orderBy };
}
