import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import CelebrityImage from '../Celebrity/CelebrityImage';
import { celebrityProfileUrl } from '../../helpers';

export default function Celebrity({ index, celebrity, text }) {
  const [imageContainer, setImageContainer] = useState(null);

  return (
    <div className="card mb-2">
      <div className="row no-gutters">
        <div className="col-2" ref={el => setImageContainer(el)}>
          <CelebrityImage
            className="card-img"
            celebrity={celebrity}
            container={imageContainer}
          />
        </div>
        <div className="col">
          <div className="card-body p-md-3 p-sm-2 p-1">
            <h5 className="card-title">
              {index + 1}.{' '}
              <Link to={celebrityProfileUrl(celebrity.id, celebrity)}>
                {celebrity.firstname} {celebrity.lastname}
              </Link>
            </h5>
            <p className="card-text text-muted">{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
