import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, union, intersection } from 'lodash';
import CountryList from 'country-list';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
} from 'recharts';
import { useParams, useHistory } from 'react-router-dom';

import { useFirebase, useHtmlElementSize } from '../../hooks';
import CelebrityImage from './CelebrityImage';
import Search from '../Search';
import Icon from '../Icon';
import COLORS from '../../constants/colors';
import { getCelebrity, getFields } from '../../reducers';
import { fetchCelebrities } from '../../actions';
import {
  celebrityCompareUrl,
  getRatingHistoryLabel,
  getRankingHistoryLabel,
} from '../../helpers';

export default function CelebrityCompare({ celebId1, celebId2 }) {
  const fields = useSelector(getFields);
  const params = useParams();
  const [celebrityId1, setCelebrityId1] = useState(
    celebId1 ? celebId1 : params.id1
  );
  const [celebrityId2, setCelebrityId2] = useState(
    celebId2 ? celebId2 : params.id2
  );

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const ids = [];
    if (celebrityId1 && celebrityId1 !== '-') {
      ids.push(celebrityId1);
    }
    if (celebrityId2 && celebrityId2 !== '-') {
      ids.push(celebrityId2);
    }
    const query = { ids };
    dispatch(fetchCelebrities(query)).then(() => {
      setLoading(false);
    });
  }, [celebrityId1, celebrityId2, dispatch]);

  const celebrity1 = useSelector(state => getCelebrity(state, celebrityId1));
  const celebrity2 = useSelector(state => getCelebrity(state, celebrityId2));

  const firebase = useFirebase();
  if (celebrity1 && celebrity1.birthdate instanceof firebase.Timestamp) {
    celebrity1.birthdate = celebrity1.birthdate.toDate();
  }
  if (celebrity2 && celebrity2.birthdate instanceof firebase.Timestamp) {
    celebrity2.birthdate = celebrity2.birthdate.toDate();
  }

  // generate graph data
  //
  // input:
  // celeb1:
  // singerRatingHistory: {
  //   2019-09-14: 1550,
  //   2019-09-15: 1600,
  // }
  // actorRatingHistory: {
  //   2019-09-14: 1400,
  //   2019-09-15: 1375,
  // }
  // celeb2:
  // singerRatingHistory: {
  //   2019-09-14: 1550,
  //   2019-09-15: 1600,
  // }
  // actorRatingHistory: {
  //   2019-09-14: 1400,
  //   2019-09-15: 1375,
  // }
  //
  // output:
  // data: {
  //   actor: [
  //     {date: 2019-09-14, celebId1: 1550, celebId2: 1400, singerRank: ..., actorRank: ...},
  //     {date: 2019-09-15, celebId1: 1600, celebId2: 1375, singerRank: ..., actorRank: ...},
  //   ],
  //   singer: [
  //     {date: 2019-09-14, celebId1: 1550, celebId2: 1400, singerRank: ..., actorRank: ...},
  //     {date: 2019-09-15, celebId1: 1600, celebId2: 1375, singerRank: ..., actorRank: ...},
  //   ],
  // }
  const celebrityFields1 = get(celebrity1, 'fields', []);
  const celebrityFields2 = get(celebrity2, 'fields', []);
  const fieldUnion = union(celebrityFields1, celebrityFields2);
  const fieldInter = intersection(celebrityFields1, celebrityFields2);

  const data = {};
  fieldInter.forEach(field => {
    const ratingHistoryLabel = getRatingHistoryLabel(field);
    const ratingHistory1 = get(celebrity1, ratingHistoryLabel, {});
    const ratingHistory2 = get(celebrity2, ratingHistoryLabel, {});
    const rankingHistoryLabel = getRankingHistoryLabel(field);
    const rankingHistory1 = get(celebrity1, rankingHistoryLabel, {});
    const rankingHistory2 = get(celebrity2, rankingHistoryLabel, {});
    // find all dates for the field
    const dates = union(
      Object.keys(ratingHistory1),
      Object.keys(ratingHistory2)
    ).sort();
    const celeb1RankingHistoryLabel = `${celebrityId1}Ranking`;
    const celeb2RankingHistoryLabel = `${celebrityId2}Ranking`;
    data[field] = dates.map(date => {
      const row = { date };
      if (ratingHistory1[date]) {
        row[celebrityId1] = Math.round(ratingHistory1[date]);
        row[celeb1RankingHistoryLabel] = rankingHistory1[date] + 1;
      }
      if (ratingHistory2[date]) {
        row[celebrityId2] = Math.round(ratingHistory2[date]);
        row[celeb2RankingHistoryLabel] = rankingHistory2[date] + 1;
      }
      return row;
    });
  });

  const isSameCountry =
    get(celebrity1, 'country', 1) === get(celebrity2, 'country', 2);
  const dataNatl = {};
  if (isSameCountry) {
    fieldInter.forEach(field => {
      const ratingHistoryLabel = getRatingHistoryLabel(field, true);
      const ratingHistory1 = get(celebrity1, ratingHistoryLabel, {});
      const ratingHistory2 = get(celebrity2, ratingHistoryLabel, {});
      const rankingHistoryLabel = getRankingHistoryLabel(field, true);
      const rankingHistory1 = get(celebrity1, rankingHistoryLabel, {});
      const rankingHistory2 = get(celebrity2, rankingHistoryLabel, {});
      // find all dates for the field
      const dates = union(
        Object.keys(ratingHistory1),
        Object.keys(ratingHistory2)
      ).sort();
      const celeb1RankingHistoryLabel = `${celebrityId1}Ranking`;
      const celeb2RankingHistoryLabel = `${celebrityId2}Ranking`;
      dataNatl[field] = dates.map(date => {
        const row = { date };
        if (ratingHistory1[date]) {
          row[celebrityId1] = Math.round(ratingHistory1[date]);
          row[celeb1RankingHistoryLabel] = rankingHistory1[date] + 1;
        }
        if (ratingHistory2[date]) {
          row[celebrityId2] = Math.round(ratingHistory2[date]);
          row[celeb2RankingHistoryLabel] = rankingHistory2[date] + 1;
        }
        return row;
      });
    });
  }

  // for labeling
  const celebrities = {
    [celebrityId1]: celebrity1,
    [celebrityId2]: celebrity2,
  };

  function getFullname(celebrity) {
    return `${celebrity.firstname} ${celebrity.lastname}`;
  }

  function tooltipValueFormatter(value, name, { payload, ...props }) {
    return [`Rating: ${value}, Rank: ${payload[`${name}Ranking`]}`];
  }

  function axisTickFormatter(value) {
    const m = moment(value);
    let text;
    if (m.month() === 0 && m.date() === 1) {
      text = m.year().toString();
    } else if (m.date() === 1) {
      text = m.format('MMM');
    } else {
      text = m.date().toString();
    }
    return text;
  }

  function legendLabelFormatter(value, entry, index) {
    return getFullname(celebrities[value]);
  }

  const history = useHistory();
  function handleCelebritySelect(id1, id2) {
    setCelebrityId1(id1);
    setCelebrityId2(id2);
    history.replace(celebrityCompareUrl(id1, id2));
  }

  // TODO: clear icon with css
  // TODO: export Header to a new component
  const [clearIcon1, setClearIcon1] = useState(null);
  const [clearIcon2, setClearIcon2] = useState(null);
  function handleCelebrityClear(event, id) {
    event.preventDefault();
    if (id === celebrityId1) {
      setClearIcon1(false);
      setCelebrityId1('-');
      history.replace(celebrityCompareUrl('-', celebrityId2));
    } else {
      setClearIcon2(false);
      setCelebrityId2('-');
      history.replace(celebrityCompareUrl(celebrityId1, '-'));
    }
  }

  const [imageContainer1, setImageContainer1] = useState(null);
  const [imageContainer2, setImageContainer2] = useState(null);
  const [chartContainer, setChartContainer] = useState(null);
  const chartSize = useHtmlElementSize(chartContainer);

  return (
    <div className="container">
      {loading ? (
        <div className="row">
          <div className="col-12 text-center my-3">
            <div
              className="spinner-grow spinner-grow-lg text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row mt-2 mb-3">
            <div className="col-sm-6 d-flex align-items-center justify-content-start border-right compare-title-left">
              {celebrity1 ? (
                <a
                  className="text-decoration-none"
                  href="#clear-left"
                  onClick={e => handleCelebrityClear(e, celebrityId1)}
                >
                  <h1
                    className="text-dark mb-0"
                    onMouseEnter={() => setClearIcon1(true)}
                    onMouseLeave={() => setClearIcon1(false)}
                  >
                    {clearIcon1 && (
                      <Icon
                        icon="times-circle"
                        size="sm"
                        className="compare-clear-icon"
                        fixedWidth
                      />
                    )}
                    {get(celebrity1, 'firstname', '')}{' '}
                    {get(celebrity1, 'lastname', '')}
                  </h1>
                </a>
              ) : (
                <Search
                  id="query1"
                  onClick={id => handleCelebritySelect(id, celebrityId2)}
                  createUrl={id => celebrityCompareUrl(id, celebrityId2)}
                />
              )}
            </div>
            <div className="col-sm-6 d-flex align-items-center justify-content-end compare-title-right">
              {celebrity2 ? (
                <a
                  className="text-decoration-none"
                  href="#clear-right"
                  onClick={e => handleCelebrityClear(e, celebrityId2)}
                >
                  <h1
                    className="text-dark mb-0"
                    onMouseEnter={() => setClearIcon2(true)}
                    onMouseLeave={() => setClearIcon2(false)}
                  >
                    {get(celebrity2, 'firstname', '')}{' '}
                    {get(celebrity2, 'lastname', '')}
                    {clearIcon2 && (
                      <Icon
                        icon="times-circle"
                        size="sm"
                        className="compare-clear-icon"
                        fixedWidth
                      />
                    )}
                  </h1>
                </a>
              ) : (
                <Search
                  id="query2"
                  onClick={id => handleCelebritySelect(celebrityId1, id)}
                  createUrl={id => celebrityCompareUrl(celebrityId1, id)}
                />
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col-6 col-lg-4 offset-lg-2 text-right border-right"
              ref={el => setImageContainer1(el)}
            >
              <CelebrityImage
                celebrity={celebrity1}
                container={imageContainer1}
              />
            </div>
            <div className="col-6 col-lg-4" ref={el => setImageContainer2(el)}>
              <CelebrityImage
                celebrity={celebrity2}
                container={imageContainer2}
              />
            </div>
          </div>
          {(celebrity1 || celebrity2) && (
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-primary">Birthdate</h4>
              </div>
            </div>
          )}
          <div className="row mb-3">
            <div className="col-6 text-right border-right">
              <span>
                {celebrity1 &&
                  celebrity1.birthdate &&
                  moment(celebrity1.birthdate).format('MMM Do YYYY')}
              </span>
            </div>
            <div className="col-6">
              <span>
                {celebrity2 &&
                  celebrity2.birthdate &&
                  moment(celebrity2.birthdate).format('MMM Do YYYY')}
              </span>
            </div>
          </div>
          {(celebrity1 || celebrity2) && (
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-primary">Country</h4>
              </div>
            </div>
          )}
          <div className="row mb-3">
            <div className="col-6 text-right border-right">
              <span>
                {celebrity1 &&
                  celebrity1.country &&
                  CountryList.getName(celebrity1.country)}
              </span>
            </div>
            <div className="col-6">
              <span>
                {celebrity2 &&
                  celebrity2.country &&
                  CountryList.getName(celebrity2.country)}
              </span>
            </div>
          </div>
          {(celebrity1 || celebrity2) && (
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-primary">International Current Rating</h4>
              </div>
            </div>
          )}
          {fieldUnion.map(field => {
            const ratingLabel = `${field}Rating`;
            return (
              <Fragment key={`${field}Rating`}>
                <div className="row">
                  <div className="col-12 text-center">
                    <h5 className="text-primary">{fields[field]}</h5>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6 text-right border-right">
                    <span>
                      {get(celebrity1, ratingLabel, null)
                        ? Math.round(celebrity1[ratingLabel])
                        : '-'}
                    </span>
                  </div>
                  <div className="col-6">
                    <span>
                      {get(celebrity2, ratingLabel, null)
                        ? Math.round(celebrity2[ratingLabel])
                        : '-'}
                    </span>
                  </div>
                </div>
              </Fragment>
            );
          })}
          {celebrity1 &&
            celebrity2 &&
            fieldInter.length > 0 &&
            fieldInter.reduce(
              (cum, cur) => cum && data[cur].length > 0,
              true
            ) && (
              <div className="row">
                <div className="col-12 text-center">
                  <h4 className="text-primary">International Rating History</h4>
                </div>
              </div>
            )}
          {fieldInter.map(
            field =>
              data[field].length > 0 && (
                <Fragment key={`${field}RatingChart`}>
                  <div className="row">
                    <div className="col-12 text-center">
                      <h5 className="text-primary">{fields[field]}</h5>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12" ref={el => setChartContainer(el)}>
                      <LineChart
                        width={chartSize[0]}
                        height={chartSize[0] / 3 + 40}
                        data={data[field]}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          tickFormatter={axisTickFormatter}
                        />
                        <YAxis domain={['dataMin - 5', 'dataMax + 5']} />
                        <Tooltip formatter={tooltipValueFormatter} />
                        <Legend
                          verticalAlign="top"
                          height={36}
                          formatter={legendLabelFormatter}
                        />
                        <Line
                          key={`${celebrityId1}Line`}
                          type="monotone"
                          dataKey={celebrityId1}
                          stroke={COLORS[0]}
                          fill={COLORS[0]}
                        />
                        <Line
                          key={`${celebrityId2}Line`}
                          type="monotone"
                          dataKey={celebrityId2}
                          stroke={COLORS[1]}
                          fill={COLORS[1]}
                        />
                        <Brush travellerWidth={15} height={25} />
                      </LineChart>
                    </div>
                  </div>
                </Fragment>
              )
          )}
          {isSameCountry &&
            fieldInter.length > 0 &&
            fieldInter.reduce(
              (cum, cur) => cum && data[cur].length > 0,
              true
            ) && (
              <div className="row">
                <div className="col-12 text-center">
                  <h4 className="text-primary">National Rating History</h4>
                </div>
              </div>
            )}
          {isSameCountry &&
            fieldInter.map(
              field =>
                dataNatl[field].length > 0 && (
                  <Fragment key={`${field}RatingChartNatl`}>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h5 className="text-primary">{fields[field]}</h5>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12" ref={el => setChartContainer(el)}>
                        <LineChart
                          width={chartSize[0]}
                          height={chartSize[0] / 3 + 40}
                          data={dataNatl[field]}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="date"
                            tickFormatter={axisTickFormatter}
                          />
                          <YAxis domain={['dataMin - 5', 'dataMax + 5']} />
                          <Tooltip formatter={tooltipValueFormatter} />
                          <Legend
                            verticalAlign="top"
                            height={36}
                            formatter={legendLabelFormatter}
                          />
                          <Line
                            key={`${celebrityId1}Line`}
                            type="monotone"
                            dataKey={celebrityId1}
                            stroke={COLORS[0]}
                            fill={COLORS[0]}
                          />
                          <Line
                            key={`${celebrityId2}Line`}
                            type="monotone"
                            dataKey={celebrityId2}
                            stroke={COLORS[1]}
                            fill={COLORS[1]}
                          />
                          <Brush travellerWidth={15} height={25} />
                        </LineChart>
                      </div>
                    </div>
                  </Fragment>
                )
            )}
        </>
      )}
    </div>
  );
}
