import { useEffect, useState, useMemo } from 'react';

const getValue = (mediaQueryLists, values, defaultValue) => {
  const index = mediaQueryLists.findIndex(mql => mql.matches);
  return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
};

export default function useMedia(queries, values, defaultValue) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mValues = useMemo(() => values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mDefaultValue = useMemo(() => defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mediaQueryLists = useMemo(() => queries.map(q => window.matchMedia(q)));

  const [value, setValue] = useState(
    getValue(mediaQueryLists, mValues, mDefaultValue)
  );

  useEffect(() => {
    const handler = () =>
      setValue(getValue(mediaQueryLists, mValues, mDefaultValue));
    mediaQueryLists.forEach(mql => mql.addListener(handler));
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, [mediaQueryLists, mValues, mDefaultValue]);

  return value;
}
