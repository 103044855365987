import { useEffect, useReducer } from 'react';
import moment from 'moment';

import * as STATUS from '../constants/celebrityStatus';
import * as REASONS from '../constants/rejectionReasons';

const INITIAL_STATE = {
  firstname: '',
  lastname: '',
  birthdate: moment().subtract(30, 'years').toDate(),
  country: '',
  fields: [],
  bio: '',
  facebook: '',
  instagram: '',
  twitter: '',
  youtube: '',
  imdb: '',
  loading: false,
  photo: null,
  oldPhoto: null,
  status: STATUS.PENDING,
  rejectionReason: REASONS.NO_REASON,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      const nextState = {
        ...state,
        ...action.state,
      };
      if (action.state.birthdate) {
        nextState.birthdate = moment(action.state.birthdate).toDate();
      }
      return nextState;
    case 'set_value':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'set_birthdate':
      return {
        ...state,
        birthdate: action.date,
      };
    case 'set_photo':
      const oldPhoto =
        typeof state.photo === 'string' ? state.photo : state.oldPhoto;
      return {
        ...state,
        photo: action.photo,
        oldPhoto,
      };
    case 'submit':
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case 'submitted':
      return {
        ...state,
        loading: false,
        oldPhoto: null,
      };
    case 'reset':
      return { ...INITIAL_STATE };
    default:
      throw new Error();
  }
}

export default function useCelebrity(initialCelebrity = null) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  useEffect(() => {
    if (initialCelebrity) {
      dispatch({ type: 'set', state: initialCelebrity });
    }
  }, [initialCelebrity]);
  return [state, dispatch];
}
