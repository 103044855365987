import React, { useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { getProfile } from '../../reducers';
import * as actions from '../../actions';

const INITIAL_STATE = {
  displayName: '',
  loading: false,
  error: null,
};

function init(initialState) {
  return {
    ...INITIAL_STATE,
    ...initialState,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'submit':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'complete':
      return {
        ...state,
        loading: false,
      };
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      throw new Error('Unknown action type');
  }
}

export default function ProfileUpdate() {
  const profile = useSelector(getProfile);
  const [state, _dispatch] = useReducer(
    reducer,
    { displayName: profile.displayName || '' },
    init
  );
  const { displayName, loading } = state;

  function handleChange(event) {
    _dispatch({
      type: 'set',
      name: event.target.name,
      value: event.target.value,
    });
  }

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  async function updateProfile() {
    try {
      _dispatch({ type: 'submit' });
      await dispatch(actions.updateProfile({ displayName }));
      _dispatch({ type: 'complete' });
      addToast('Your profile updated.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      _dispatch({ type: 'error', error });
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    updateProfile();
  }

  const isInvalid = !displayName || displayName === profile.displayName;
  return (
    <form className="form-auth" onSubmit={handleSubmit} autoComplete="off">
      <div className="text-center mb-4">
        <h1 className="h3 mb-3 font-weight-normal">Update Profile</h1>
      </div>
      <div className="form-label-group">
        <input
          type="text"
          className="form-control"
          placeholder="Display name"
          required
          autoFocus
          name="displayName"
          onChange={handleChange}
          value={displayName}
        />
        <label htmlFor="inputEmail">Display name</label>
      </div>
      <button
        disabled={isInvalid}
        className="btn btn-primary btn-block"
        type="submit"
      >
        {loading ? (
          <>
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          'Save'
        )}
      </button>
    </form>
  );
}
