import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../hooks';
import { updatePassword } from '../../actions';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'submit':
      return {
        ...state,
        loading: true,
      };
    case 'complete':
      return {
        ...state,
        passwordOne: '',
        passwordTwo: '',
        loading: false,
      };
    case 'error':
      return {
        ...state,
        passwordOne: '',
        passwordTwo: '',
        loading: false,
        error: action.error,
      };
    default:
      throw new Error('Unknown action type');
  }
}

export default function PasswordChange() {
  const [state, _dispatch] = useReducer(reducer, INITIAL_STATE);
  const { passwordOne, passwordTwo, loading } = state;

  function handleChange(event) {
    _dispatch({
      type: 'set',
      name: event.target.name,
      value: event.target.value,
    });
  }

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { history } = useRouter();
  async function changePassword() {
    try {
      _dispatch({ type: 'submit' });
      await dispatch(updatePassword(passwordOne));
      _dispatch({ type: 'complete' });
      addToast('Your password was changed.', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push(ROUTES.ACCOUNT_SETTINGS);
    } catch (error) {
      _dispatch({ type: 'error', error });
      if (error.code === 'auth/requires-recent-login') {
        addToast(error.message, {
          appearance: 'warning',
          autoDismiss: true,
        });
        history.push(
          `${ROUTES.SIGNIN}?redirect=${ROUTES.ACCOUNT_PASSWORD_CHANGE}`
        );
      } else {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    changePassword();
  }

  const isInvalid = !passwordOne || !passwordTwo || passwordOne !== passwordTwo;
  return (
    <form className="form-auth" onSubmit={handleSubmit} autoComplete="off">
      <div className="text-center mb-4">
        <h1 className="h3 mb-3 font-weight-normal">Change Password</h1>
      </div>
      <div className="form-label-group">
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          required
          name="passwordOne"
          onChange={handleChange}
          value={passwordOne}
        />
        <label htmlFor="inputPassword">Password</label>
      </div>
      <div className="form-label-group">
        <input
          type="password"
          className="form-control"
          placeholder="Confirm password"
          required
          name="passwordTwo"
          onChange={handleChange}
          value={passwordTwo}
        />
        <label htmlFor="inputPassword">Confirm password</label>
      </div>
      <button
        disabled={isInvalid}
        className="btn btn-primary btn-block"
        type="submit"
      >
        {loading ? (
          <>
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          'Save'
        )}
      </button>
    </form>
  );
}

export function PasswordChangeButton() {
  const { history } = useRouter();

  function handleClick() {
    history.push(ROUTES.ACCOUNT_PASSWORD_CHANGE);
  }

  return (
    <div className="mb-2">
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={handleClick}
      >
        Change Password
      </button>
    </div>
  );
}
