import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, uniq, flatten } from 'lodash';
import CountryList from 'country-list';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
} from 'recharts';
import { Link } from 'react-router-dom';

import {
  useRouter,
  useFavorites,
  useFirebase,
  useAdminAuthorization,
  useHtmlElementSize,
} from '../../hooks';
import CelebrityImage from './CelebrityImage';
import COLORS from '../../constants/colors';
import { getCelebrity, getFields } from '../../reducers';
import { fetchCelebrity } from '../../actions';
import * as ROUTES from '../../constants/routes';
import Search from '../Search';
import {
  celebrityCompareUrl,
  getRankingHistoryLabel,
  getRatingHistoryLabel,
} from '../../helpers';
import Icon from '../Icon';
import MoreText from '../MoreText';

// TODO: extract presentational component
export default function CelebrityProfile({ celebrityId }) {
  const isAdmin = useAdminAuthorization(false);
  const fields = useSelector(getFields);
  const { match } = useRouter();
  if (!celebrityId) {
    celebrityId = match.params.id;
  }

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCelebrity(celebrityId)).then(() => {
      setLoading(false);
    });
  }, [celebrityId, dispatch]);

  const celebrity = useSelector(state => getCelebrity(state, celebrityId));

  const firebase = useFirebase();
  if (celebrity && celebrity.birthdate instanceof firebase.Timestamp) {
    celebrity.birthdate = celebrity.birthdate.toDate();
  }

  // generate graph data
  //
  // input:
  // singerRatingHistory: {
  //   2019-09-14: 1550,
  //   2019-09-15: 1600,
  // }
  // actorRatingHistory: {
  //   2019-09-14: 1400,
  //   2019-09-15: 1375,
  // }
  //
  // output:
  // data: [
  //   {date: 2019-09-14, singer: 1550, actor: 1400, singerRank: ..., actorRank: ...},
  //   {date: 2019-09-15, singer: 1600, actor: 1375, singerRank: ..., actorRank: ...},
  // ]
  const celebrityFields = get(celebrity, 'fields', []);
  const ratingHistories = celebrityFields.map(field => {
    const historyLabel = getRatingHistoryLabel(field);
    return celebrity[historyLabel] || {};
  });
  const rankingHistories = celebrityFields.map(field => {
    const historyLabel = getRankingHistoryLabel(field);
    return celebrity[historyLabel] || {};
  });
  const ratingHistoriesNatl = celebrityFields.map(field => {
    const historyLabel = getRatingHistoryLabel(field, true);
    return celebrity[historyLabel] || {};
  });
  const rankingHistoriesNatl = celebrityFields.map(field => {
    const historyLabel = getRankingHistoryLabel(field, true);
    return celebrity[historyLabel] || {};
  });
  // find all dates
  const dates = uniq(
    flatten(ratingHistories.map(history => Object.keys(history)))
  ).sort();
  const data = dates.map(date => {
    const row = { date };
    ratingHistories.forEach((history, idx) => {
      if (history[date]) {
        const field = celebrityFields[idx];
        row[field] = Math.round(history[date]);
        row[`${field}Ranking`] = rankingHistories[idx][date] + 1;
      }
    });
    return row;
  });
  // find all dates for national
  const datesNatl = uniq(
    flatten(ratingHistoriesNatl.map(history => Object.keys(history)))
  ).sort();
  const dataNatl = datesNatl.map(date => {
    const row = { date };
    ratingHistoriesNatl.forEach((history, idx) => {
      if (history[date]) {
        const field = celebrityFields[idx];
        row[field] = Math.round(history[date]);
        row[`${field}Ranking`] = rankingHistoriesNatl[idx][date] + 1;
      }
    });
    return row;
  });

  function tooltipValueFormatter(value, name, { payload, ...props }) {
    return [
      `Rating: ${value}, Rank: ${payload[`${name}Ranking`]}`,
      fields[name],
    ];
  }

  function axisTickFormatter(value) {
    const m = moment(value);
    let text;
    if (m.month() === 0 && m.date() === 1) {
      text = m.year().toString();
    } else if (m.date() === 1) {
      text = m.format('MMM');
    } else {
      text = m.date().toString();
    }
    return text;
  }

  function legendLabelFormatter(value, entry, index) {
    return fields[value];
  }

  const { favorites, addFavorite, removeFavorite } = useFavorites();
  const isInFavorites = favorites.includes(celebrityId);
  function handleClick(event) {
    event.preventDefault();
    if (isInFavorites) {
      removeFavorite(celebrity);
    } else {
      addFavorite(celebrity);
    }
  }

  const [imageContainer, setImageContainer] = useState(null);
  const [ratingChartContainer, setRatingChartContainer] = useState(null);
  const chartSize = useHtmlElementSize(ratingChartContainer);

  let birthdate, country;
  if (celebrity) {
    birthdate = celebrity.birthdate
      ? moment(celebrity.birthdate).format('MMM Do YYYY')
      : null;
    country = CountryList.getName(celebrity.country);
  }

  return (
    <div className="container">
      {loading ? (
        <div className="row">
          <div className="col-12 text-center mt-3">
            <div
              className="spinner-grow spinner-grow-lg text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : !celebrity ? (
        <div className="row">
          <div className="col-12 text-center">
            <span className="text-primary">Celebrity not found.</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <h1 className="text-dark">
                {celebrity.firstname} {celebrity.lastname}{' '}
                <a
                  className="badge btn text-danger"
                  onClick={handleClick}
                  href="#fav"
                >
                  {isInFavorites ? (
                    <Icon icon="heart" fixedWidth />
                  ) : (
                    <Icon icon={['far', 'heart']} fixedWidth />
                  )}
                </a>
                {isAdmin && (
                  <Link
                    className="badge btn"
                    to={`${ROUTES.ADMIN_CELEBRITY_DETAIL}/${celebrity.id}`}
                  >
                    <Icon icon="edit" fixedWidth className="text-dark" />
                  </Link>
                )}
              </h1>
            </div>
          </div>
          <div className="row">
            <div
              className="col-sm-4 text-center"
              ref={el => setImageContainer(el)}
            >
              <CelebrityImage
                celebrity={celebrity}
                container={imageContainer}
              />
            </div>
            <div className="col-sm-8">
              <div className="mb-3">
                <Search
                  createUrl={(id, c) => celebrityCompareUrl(celebrityId, id)}
                  id="compareQuery"
                  label="Compare with"
                />
              </div>
              {!!celebrity.bio && (
                <>
                  <h4 className="text-primary">Biography</h4>
                  <MoreText className="ml-5" text={celebrity.bio} />
                </>
              )}
              {!!birthdate && (
                <>
                  <h4 className="text-primary">Birthdate</h4>
                  <p className="ml-5">{birthdate}</p>
                </>
              )}
              {country && (
                <>
                  <h4 className="text-primary">Country</h4>
                  <p className="ml-5">{country}</p>
                </>
              )}
              {(celebrity.facebook ||
                celebrity.instagram ||
                celebrity.twitter ||
                celebrity.youtube ||
                celebrity.imdb) && (
                <>
                  <h4 className="text-primary">Profiles</h4>
                  <ul class="list-inline ml-5">
                    {celebrity.facebook && (
                      <li class="list-inline-item ml-2">
                        <a
                          href={celebrity.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-dark"
                        >
                          <Icon icon={['fab', 'facebook']} size="2x" />
                        </a>
                      </li>
                    )}
                    {celebrity.instagram && (
                      <li class="list-inline-item ml-2">
                        <a
                          href={celebrity.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-dark"
                        >
                          <Icon icon={['fab', 'instagram']} size="2x" />
                        </a>
                      </li>
                    )}
                    {celebrity.twitter && (
                      <li class="list-inline-item ml-2">
                        <a
                          href={celebrity.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-dark"
                        >
                          <Icon icon={['fab', 'twitter']} size="2x" />
                        </a>
                      </li>
                    )}
                    {celebrity.youtube && (
                      <li class="list-inline-item ml-2">
                        <a
                          href={celebrity.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-dark"
                        >
                          <Icon icon={['fab', 'youtube']} size="2x" />
                        </a>
                      </li>
                    )}
                    {celebrity.imdb && (
                      <li class="list-inline-item ml-2">
                        <a
                          href={celebrity.imdb}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-dark"
                        >
                          <Icon icon={['fab', 'imdb']} size="2x" />
                        </a>
                      </li>
                    )}
                  </ul>
                </>
              )}
              {data.length > 0 && (
                <>
                  <h4 className="text-primary">International Rating History</h4>
                  <div
                    className="d-flex justify-content-center mb-3"
                    ref={el => setRatingChartContainer(el)}
                  >
                    <LineChart
                      width={chartSize[0]}
                      height={chartSize[0] / 2 + 40}
                      data={data}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={axisTickFormatter} />
                      <YAxis domain={['dataMin - 5', 'dataMax + 5']} />
                      <Tooltip formatter={tooltipValueFormatter} />
                      <Legend
                        verticalAlign="top"
                        height={36}
                        formatter={legendLabelFormatter}
                      />
                      {celebrityFields.map((field, idx) => (
                        <Line
                          key={`${field}Line`}
                          type="monotone"
                          dataKey={field}
                          stroke={COLORS[idx]}
                          fill={COLORS[idx]}
                        />
                      ))}
                      <Brush travellerWidth={15} height={25} />
                    </LineChart>
                  </div>
                </>
              )}
              {dataNatl.length > 0 && (
                <>
                  <h4 className="text-primary">National Rating History</h4>
                  <div
                    className="d-flex justify-content-center"
                    ref={el => setRatingChartContainer(el)}
                  >
                    <LineChart
                      width={chartSize[0]}
                      height={chartSize[0] / 2 + 40}
                      data={dataNatl}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={axisTickFormatter} />
                      <YAxis domain={['dataMin - 5', 'dataMax + 5']} />
                      <Tooltip formatter={tooltipValueFormatter} />
                      <Legend
                        verticalAlign="top"
                        height={36}
                        formatter={legendLabelFormatter}
                      />
                      {celebrityFields.map((field, idx) => (
                        <Line
                          key={`${field}Line`}
                          type="monotone"
                          dataKey={field}
                          stroke={COLORS[idx]}
                          fill={COLORS[idx]}
                        />
                      ))}
                      <Brush travellerWidth={15} height={25} />
                    </LineChart>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
