import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { range } from 'lodash';

import { useCelebrityByScore } from '../../hooks';
import { getCurrentField, getCurrentCountry } from '../../reducers';
import CelebrityCard from './CelebrityCard';

const BEGINING_YEAR = 2019;
const YEARS = range(BEGINING_YEAR, moment().year() + 1);

export default function AnnualRanking() {
  const field = useSelector(getCurrentField);
  const country = useSelector(getCurrentCountry);
  const [year, setYear] = useState(YEARS[YEARS.length - 1]);
  const {
    celebrities,
    isFetching,
    error,
    more,
    endOfResult,
    orderBy,
  } = useCelebrityByScore(field, year, country);

  function handleYearChange(event) {
    setYear(event.target.value);
  }

  function renderCelebrities(celebrities) {
    return celebrities.map((celebrity, idx) => (
      <CelebrityCard
        key={celebrity.id}
        index={idx}
        celebrity={celebrity}
        text={`Score: ${Math.round(celebrity[orderBy] * 100) / 100}`}
      />
    ));
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <h3 className="text-primary">Annual Rankings</h3>
        </div>
        <div className="col-auto d-flex justify-content-end">
          <form className="form-inline">
            <select
              className="form-control ml-2"
              name="year"
              onChange={handleYearChange}
              value={year}
            >
              {YEARS.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </form>
        </div>
      </div>
      {!isFetching && !celebrities.length ? (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <span className="text-primary">
              Suggest more and Vote more to see someone here.
            </span>
          </div>
        </div>
      ) : (
        renderCelebrities(celebrities)
      )}
      {!isFetching && error && (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <span className="text-danger">{error.message}</span>
          </div>
        </div>
      )}
      {!!celebrities.length && endOfResult && (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <span className="text-primary">No more celebrity.</span>
          </div>
        </div>
      )}
      {!endOfResult && (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <button
              className="btn btn-secondary text-primary"
              type="button"
              onClick={more}
            >
              {isFetching ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm text-primary"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                'More'
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
