import React from 'react';

export default function CookiesUse() {
  return (
    <div className="container">
      <h1>Cookies</h1>
      <h2>Cookies on Reputelo</h2>
      <p>
        Our privacy policy describes how we collect and use information, and
        what choices you have. One way we collect information is through the use
        of a technology called “cookies", and similar technologies, such as
        pixels, local storage and software development kits (SDKs). We use
        cookies for all kinds of things on Reputelo.
      </p>
      <h2>What is a cookie</h2>
      <p>
        When you go online, you use a program called a “browser”. Most websites
        store a small amount of text in the browser—and that text is called a
        “cookie.” Other technologies function similarly to collect data about
        your browsing activity. This policy refers to these technologies
        collectively as “cookies.”
      </p>
      <p>
        We use session cookies (that last until you close your browser) or
        persistent cookies (that last until you or your browser delete them).
        For example, we use persistent cookies to store your settings so you
        don’t have to set them up every time you visit Reputelo.
      </p>
      <p>
        Some of the cookies we use are associated with your Reputelo account
        (including information about you, such as the email address you gave us)
        and other cookies are not.
      </p>
      <h2>How we use cookies</h2>
      <p>We have four categories of cookies:</p>
      <ul>
        <li>
          Essential cookies are required so Reputelo works in the way you
          expect. Examples of these types of cookies are login cookies which
          keep you logged in as you scroll through Reputelo.
        </li>
        <li>
          Analytics cookies which are used for internal analytics and include
          cookies from providers like Google Analytics.
        </li>
        <li>
          Personalization cookies for customizing content, including ads,
          presented to you on Reputelo.
        </li>
        <li>
          Marketing cookies which are used for advertising Reputelo to you off
          of Reputelo.
        </li>
      </ul>
      <h2>What we do with the cookies in each category?</h2>
      <h3>Essential cookies</h3>
      <ul>
        <li>
          We use essential cookies to remember your settings and preferences.
        </li>
        <li>Essential cookies let you log in and out of Reputelo.</li>
        <li>
          Essential cookies are just one way we protect you from security risks.
          For example, we use them to detect when someone might be trying to
          hack your Reputelo account or spam the Reputelo community.
        </li>
      </ul>
      <h3>Analytics cookies</h3>
      <ul>
        <li>
          We use analytics cookies to make Reputelo better. For example, these
          cookies tell us whether people open an email we send.
        </li>
        <li>
          We also use cookies to help advertisers understand who sees their ads,
          and who visits their website or purchases their products.
        </li>
        <li>
          Sometimes we hire security vendors or use third-party analytics
          providers to help us understand how people are using Reputelo. Just
          like we do, these providers may use cookies.
        </li>
      </ul>
      <h3>Personalization cookies</h3>
      <ul>
        <li>We use cookies to help advertisers show you interesting ads.</li>
      </ul>
      <h3>Marketing cookies</h3>
      <ul>
        <li>
          Sometimes we hire advertising vendors so that we can market Reputelo
          products on other websites and apps. These providers may use cookies
          to provide this service to us.
        </li>
      </ul>
      <h2>Where we use cookies</h2>
      <p>
        We use cookies on our website, in our mobile applications, and in our
        products and services (like ads, emails and applications).
      </p>
      <h2>Your options</h2>
      <p>
        There are a few ways you can choose how cookies are used on Reputelo:
      </p>
      <p>
        Your browser probably gives you cookie choices. For example, most
        browsers let you block “third-party cookies,” which are cookies from
        sites other than the one you’re visiting. Those options vary from
        browser to browser, so check your browser settings for more info.
      </p>
      <p>
        Some browsers also have a privacy setting called “Do Not Track,” which
        we support. This setting is another way for you to decide whether we use
        info from our partners and other services to customize Reputelo for you.
      </p>
      <p>
        Finally, your mobile device has settings that allow you to control
        whether ad partners can use information about your use of mobile
        applications to show ads to you. On iOS devices, this setting is called
        “Limit Ad Tracking” and on Android devices, this setting is called “Opt
        out of Ads Personalization.”
      </p>
      <p>
        Effective date: November 1, 2019
        <br />
        Last updated: October 2, 2019
      </p>
    </div>
  );
}
