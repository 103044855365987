import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import Cookies from 'js-cookie';

import Icon from './Icon';

const cookieName = 'cookie-use';

export default function Cookie() {
  const [isAccepted, setIsAccepted] = useState(Cookies.get(cookieName));

  function handleClose() {
    Cookies.set(cookieName, true, { expires: 365 * 10 });
    setIsAccepted(true);
  }

  if (isAccepted) {
    return null;
  }

  return (
    <div className="cookie-notification">
      <div className="container p-2">
        <div className="row">
          <div className="col text-center">
            We use cookies for Reputelo's basic functionality and analytics. By
            continuing to use Reputelo, you agree with our{' '}
            <Link to={ROUTES.CU}>Cookie Policy</Link>.
          </div>
          <div className="col-auto d-flex justify-content-center align-items-center">
            <button className="btn p-0" onClick={handleClose}>
              <Icon icon="times" fixedWidth />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
