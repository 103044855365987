import React from 'react';

export default function ContactUs() {
  return (
    <div className="container">
      <h1>Reputelo</h1>
      <p>Please send an email to contact us.</p>
      <address>
        <strong>E-Mail:</strong>
        <br />
        <a href="mailto:info.reputelo@gmail.com">info.reputelo@gmail.com</a>
      </address>
      <address>
        <strong>Please Donate to Reputelo at Patreon:</strong>
        <br />
        <a
          href="https://www.patreon.com/reputelo"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click for Donation
        </a>
      </address>
    </div>
  );
}
