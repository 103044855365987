import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useRouter } from '../../hooks';
import CelebrityForm from '../Celebrity/CelebrityForm';
import { getCelebrity } from '../../reducers';
import { fetchCelebrity } from '../../actions';

export default function CelebrityItem() {
  const { match } = useRouter();
  const celebrityId = match.params.id;

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCelebrity(celebrityId)).then(() => {
      setLoading(false);
    });
  }, [celebrityId, dispatch]);

  const celebrity = useSelector(state => getCelebrity(state, celebrityId));

  return (
    <div className="container">
      {loading ? (
        <p>Loading...</p>
      ) : !celebrity ? (
        <p>Celebrity not found.</p>
      ) : (
        <CelebrityForm initialCelebrity={celebrity} celebrityId={celebrityId} />
      )}
    </div>
  );
}
