import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useFirebase } from '../../hooks';

export default function CelebrityItem() {
  const [loading, setLoading] = useState(false);
  const firebase = useFirebase();
  const { addToast } = useToasts();

  async function handleCreateLevels() {
    setLoading(true);
    try {
      await firebase.createLevels();
      addToast('Levels have been created.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="container mt-2">
      <button className="btn btn-primary" onClick={handleCreateLevels}>
        {loading ? (
          <>
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          'Create Levels'
        )}
      </button>
    </div>
  );
}
