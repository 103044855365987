import React from 'react';

export default function About() {
  return (
    <div className="container">
      <h2>How Do I Vote?</h2>
      <p>
        First of all, select the field and country that you want to vote on the
        "Vote" page.After making field and country selections you will see two
        celebrities. Which one you like more, you can click that celebrity's
        photo and use your vote or if you do not want to vote for one of the two
        celebrities in front of you by clicking the “Skip” button you can access
        the option to vote for other celebrities in the same field and country.
        If you want to compare the two celebrities ahead of you while on the
        “Vote” page, you can click the “Compare” button.
      </p>
      <h2>What’s Going On Reputelo?</h2>
      <p>
        Reputelo, calculates current celebrity ratings and annual celebrity
        rankings of celebrities-who are still alive and active in their
        careers-based on the votes cast by users who are members of our site
        from all over the world.
      </p>
      <h2>Can I Suggest Celebrity To Reputelo?</h2>
      <p>
        Of course. Once you are a member of Reputelo, click on the “Suggest a
        celebrity” link on the “Home” page. Enter the Firstname-Lastname-Date of
        Birth-Country-Profiles-Field of your celebrity you want to suggest
        correctly and completely. Upload a photo of your celebrity. When
        uploading a photo, you need to select a quality photo. If the photo
        quality is not at the desired level, your suggestion may be rejected by
        our admins. Once you have entered all the information completely and
        correctly, press the “Submit” button. After this stage, your suggestion
        will be transferred to the approval department for evaluation by our
        admins. If the person you suggest is a celebrity person and the
        information is correct, our site accepts your suggestion. You can now
        encounter the celebrity you suggested while voting on our site and use
        your vote to celebrity that you admire. It's that simple!!!
      </p>
      <h2>Who Can Be Suggested For Newborn Field?</h2>
      <p>
        You are like a newborn baby on social media and you want to be famous.
        However, you have no idea how to achieve this. Do not worry! Reputelo is
        here for you. Suggest yourself to Reputelo by filling your information
        as a newborn. After getting the approval from our admins, your fans can
        vote for you in the newborn field. Invite everyone to Reputelo to vote
        for you. Every week, a date will be announced in Reputelo's social media
        accounts. On this date, the winner of the week will be determined
        according to the International-Newborn Current Rating Score at a random
        hour. Winner will be upgraded to Crawling field.
      </p>
      <h2>What Happens When You Are Upgraded To The Crawling Field?</h2>
      <p>
        You are no longer a newborn baby. You are growing up with Reputelo and
        you have just started crawling. You are on Crawling field. Your new goal
        is to upgrade to the Influencer field. To achieve this, you need to
        increase the number of followers(subscribers) at least one of your
        social media accounts to over 10K. As soon as you achieve this, come to
        Reputelo's website and suggest yourself as an influencer. After getting
        the approval from our admins, you can now compete against the best
        influencers with your fans' votes. Congrats! We succeeded by growing up
        together!
      </p>
      <h2>How Is Happening The Voting From The Beginning?</h2>
      <p>
        After accepting the user-suggested celebrity, she/he is included in the
        voting with other celebrities who are present to take a number of
        comparison tests determined by our algorithm. This test is performed
        between celebrities in the same field and country by using the{' '}
        <a
          href="https://en.wikipedia.org/wiki/Elo_rating_system"
          target="_blank"
          rel="noopener noreferrer"
        >
          Elo rating system
        </a>
        . Our aim in doing this test is clear. Our aim is to let the newly
        accepted celebrity step into the current celebrity rating list from the
        level of realistic admiration love with the end of the comparison test.
      </p>
      <h2>How Are Current Celebrity Ratings Calculated?</h2>
      <p>
        Every celebrity who finishes the comparison test is included in our
        current celebrity rating list and in each voting process, the current
        celebrity rating score changes according to the Elo rating system used
        in our algorithm. Every celebrity in the current celebrity rating list
        is going to vote with celebrities who have about the same current
        celebrity ratings thanks to our algorithm. And again, according to our
        algorithm, each celebrity has the chance to vote close to each other.
      </p>
      <h2>How Are Annual Celebrity Rankings Calculated?</h2>
      <p>
        At the end of each day, each celebrity is given a ranking score
        according to the ranking in the current celebrity rating list. When the
        calculation of these ranking scores is completed in 1 year, the most
        popular celebrity of the year is selected in each field and country.
      </p>
    </div>
  );
}
