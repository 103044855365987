import React, { useCallback, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import CountryList from 'country-list';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useDropzone } from 'react-dropzone';
import v4 from 'uuid/v4';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import {
  useCelebrity,
  useAdminAuthorization,
  useRouter,
  useFirebase,
  useMedia,
} from '../../hooks';
import { photosPath } from '../../config';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/celebrityStatus';
import * as REASONS from '../../constants/rejectionReasons';
import CelebrityImage from './CelebrityImage';
import { deleteCelebrity } from '../../actions';
import { getAuthUser, getFields } from '../../reducers';
import DeleteDialog from '../DeleteDialog';

export default function CelebrityForm({
  title = null,
  initialCelebrity = null,
  celebrityId = null,
  onSubmitSuccess = null,
}) {
  const isAdmin = useAdminAuthorization(false);
  const [celebrity, _dispatch] = useCelebrity(initialCelebrity);
  const firebase = useFirebase();
  const { history } = useRouter();
  const authUser = useSelector(getAuthUser);
  const { addToast } = useToasts();
  const allFields = useSelector(getFields);

  const onDrop = useCallback(
    acceptedFiles => {
      _dispatch({ type: 'set_photo', photo: acceptedFiles[0] });
    },
    [_dispatch]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const isSmallScreen = useMedia(['(min-width: 576px)'], [false], true);

  const {
    firstname,
    lastname,
    birthdate,
    country,
    fields,
    bio,
    facebook,
    instagram,
    twitter,
    youtube,
    imdb,
    loading,
    photo,
    oldPhoto,
    status,
    rejectionReason,
  } = celebrity;

  function handleValueChange(event) {
    const target = event.target;
    _dispatch({
      type: 'set_value',
      name: target.name,
      value: target.type === 'checkbox' ? target.checked : target.value,
    });
  }

  function handleFieldChange(event) {
    const fields = [...event.target.selectedOptions].map(
      option => option.value
    );
    _dispatch({
      type: 'set_value',
      name: 'fields',
      value: fields,
    });
  }

  function handleStatusChange(event) {
    const target = event.target;
    const value = Number.parseInt(target.value);
    _dispatch({
      type: 'set_value',
      name: target.name,
      value,
    });
    if (value !== STATUS.REJECTED) {
      _dispatch({
        type: 'set_value',
        name: 'rejectionReason',
        value: null,
      });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    _dispatch({ type: 'submit' });
    const celebrityData = {
      firstname,
      lastname,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      birthday: moment(birthdate).format('MM-DD'),
      country,
      fields,
      bio,
      facebook,
      instagram,
      twitter,
      youtube,
      imdb,
      status: isAdmin ? status : STATUS.PENDING,
    };

    if (!photo) {
      addToast('The celebrity must have a photo.', {
        appearance: 'error',
        autoDismiss: true,
      });
      _dispatch({ type: 'submitted' });
      return;
    }

    if (status === STATUS.APPROVED) {
      celebrityData.approvedBy = authUser.uid;
    } else if (celebrityId) {
      celebrityData.approvedBy = firebase.FieldValue.delete();
    }
    if (status === STATUS.REJECTED) {
      celebrityData.rejectedBy = authUser.uid;
      celebrityData.rejectionReason = rejectionReason;
    } else if (celebrityId) {
      celebrityData.rejectedBy = firebase.FieldValue.delete();
      celebrityData.rejectionReason = firebase.FieldValue.delete();
    }

    const promises = [];
    let successMessage;

    // delete old photo if exists
    const storage = firebase.storage.ref();
    if (oldPhoto) {
      const oldFile = storage.child(oldPhoto);
      promises.push(oldFile.delete().catch(error => error));
    }

    // upload photo if exists
    if (photo instanceof File) {
      const filePath = `${photosPath}/${v4()}`;
      const file = storage.child(filePath);
      promises.push(
        file
          .put(photo)
          .then(snapshot => {
            celebrityData.photo = filePath;
            return snapshot.ref.getDownloadURL();
          })
          .then(url => {
            celebrityData.photoUrl = url;
          })
      );
    }

    Promise.all(promises)
      .then(() => {
        if (celebrityId) {
          celebrityData.updatedAt = firebase.FieldValue.serverTimestamp();
          celebrityData.updatedBy = authUser.uid;
          successMessage = 'Celebrity updated.';
          return firebase.celebrity(celebrityId).update(celebrityData);
        } else {
          celebrityData.createdBy = authUser.uid;
          celebrityData.createdAt = firebase.FieldValue.serverTimestamp();
          successMessage = 'Celebrity saved.';
          return firebase.celebrities().add(celebrityData);
        }
      })
      .then(() => {
        addToast(successMessage, {
          appearance: 'success',
          autoDismiss: true,
        });
        if (celebrityId) {
          _dispatch({ type: 'submitted' });
        } else {
          _dispatch({ type: 'reset' });
        }
        onSubmitSuccess && onSubmitSuccess();
      })
      .catch(error => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        _dispatch({ type: 'submitted' });
      });
  }

  const dispatch = useDispatch();

  function handleDelete() {
    const promises = [];

    // delete old photo if exists
    const storage = firebase.storage.ref();
    if (oldPhoto) {
      const oldFile = storage.child(oldPhoto);
      promises.push(oldFile.delete().catch(error => error));
    }

    // delete photo if exists
    if (typeof photo === 'string') {
      const file = storage.child(photo);
      promises.push(file.delete().catch(error => error));
    }

    promises.push(dispatch(deleteCelebrity(celebrityId)));

    Promise.all(promises)
      .then(() => {
        addToast('The celebrity was deleted.', {
          appearance: 'success',
          autoDismiss: true,
        });
        history.push(ROUTES.ADMIN_CELEBRITY_LIST);
      })
      .catch(error => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  }

  const [isOpen, setIsOpen] = useState(false);

  function toggleDeleteDialog() {
    setIsOpen(!isOpen);
  }

  const imageContainerRef = useRef(null);

  const fullname = `${firstname} ${lastname}`;

  const imageHelp = isDragActive
    ? 'Drop the photo'
    : photo
    ? 'Select or drag and drop another photo to change it'
    : 'Select or drag and drop a photo (required)';
  return (
    <div className="container">
      <h1 className="text-dark">{title ? title : fullname}</h1>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div {...getRootProps()} ref={imageContainerRef}>
              <input {...getInputProps()} />
              <CelebrityImage
                celebrity={celebrity}
                container={imageContainerRef.current}
                helpText={imageHelp}
              />
            </div>
          </div>
          <div className="col-lg-8 col-sm-6">
            <div className="form-group">
              <label htmlFor="firstname">Firstname (required)</label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                name="firstname"
                placeholder="Firstname"
                value={firstname}
                onChange={handleValueChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname">Lastname</label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                name="lastname"
                placeholder="Lastname"
                value={lastname}
                onChange={handleValueChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="birthdate">Birthdate</label>
              <DatePicker
                selected={birthdate}
                onChange={date => _dispatch({ type: 'set_birthdate', date })}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={new Date()}
                customInput={<DatePickerInput isSmallScreen={isSmallScreen} />}
                popperClassName="react-datepicker-popper-extra"
                withPortal={isSmallScreen}
                openToDate={birthdate}
              />
            </div>
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <select
                className="form-control"
                id="country"
                name="country"
                onChange={handleValueChange}
                value={country}
              >
                <option value="">Select a country</option>
                {CountryList.getCodes().map(code => (
                  <option key={code} value={code}>
                    {CountryList.getName(code)}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="field">Fields</label>
              <select
                className="form-control"
                id="field"
                name="field"
                onChange={handleFieldChange}
                multiple={true}
                value={fields}
                aria-describedby="fieldHelp"
              >
                {Object.keys(allFields).map(key => (
                  <option key={key} value={key}>
                    {allFields[key]}
                  </option>
                ))}
              </select>
              <small id="fieldHelp" className="form-text text-muted">
                Use <kbd>ctrl</kbd> or <kbd>command</kbd> to select more than
                one field
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="bio">Biography</label>
              <textarea
                className="form-control"
                name="bio"
                id="bio"
                placeholder="Short Bio..."
                value={bio}
                onChange={handleValueChange}
                rows="3"
              />
            </div>
            <div className="form-group">
              <label htmlFor="facebook">Facebook URL</label>
              <input
                type="text"
                className="form-control"
                id="facebook"
                name="facebook"
                placeholder="https://www.facebook.com/..."
                value={facebook}
                onChange={handleValueChange}
                pattern="^(http|https)://([^\.].*\.)?facebook.com/.+"
              />
            </div>
            <div className="form-group">
              <label htmlFor="instagram">Instagram URL</label>
              <input
                type="text"
                className="form-control"
                id="instagram"
                name="instagram"
                placeholder="https://www.instagram.com/..."
                value={instagram}
                onChange={handleValueChange}
                pattern="^(http|https)://([^\.].*\.)?instagram.com/.+"
              />
            </div>
            <div className="form-group">
              <label htmlFor="twitter">Twitter URL</label>
              <input
                type="text"
                className="form-control"
                id="twitter"
                name="twitter"
                placeholder="https://twitter.com/..."
                value={twitter}
                onChange={handleValueChange}
                pattern="^(http|https)://([^\.].*\.)?twitter.com/.+"
              />
            </div>
            <div className="form-group">
              <label htmlFor="youtube">Youtube URL</label>
              <input
                type="text"
                className="form-control"
                id="youtube"
                name="youtube"
                placeholder="https://www.youtube.com/..."
                value={youtube}
                onChange={handleValueChange}
                pattern="^(http|https)://([^\.].*\.)?youtube.com/.+"
              />
            </div>
            <div className="form-group">
              <label htmlFor="imdb">IMDb URL</label>
              <input
                type="text"
                className="form-control"
                id="imdb"
                name="imdb"
                placeholder="https://www.imdb.com/..."
                value={imdb}
                onChange={handleValueChange}
                pattern="^(http|https)://([^\.].*\.)?imdb.com/.+"
              />
            </div>
            {isAdmin && (
              <>
                <div className="form-group">
                  <label htmlFor="status">Status (required)</label>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={handleStatusChange}
                    value={status}
                    required
                  >
                    {Object.keys(STATUS).map(key => (
                      <option key={key} value={STATUS[key]}>
                        {key}
                      </option>
                    ))}
                  </select>
                </div>
                {status === STATUS.REJECTED && (
                  <div className="form-group">
                    <label htmlFor="rejectionReason">
                      Rejection Reason (required)
                    </label>
                    <select
                      className="form-control"
                      id="rejectionReason"
                      name="rejectionReason"
                      onChange={handleValueChange}
                      value={rejectionReason}
                      required
                    >
                      {Object.keys(REASONS).map(key => (
                        <option key={key} value={key}>
                          {REASONS[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </>
            )}
            <button
              className="btn btn-primary mr-2"
              disabled={loading}
              type="submit"
              style={{ width: '5rem' }}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </>
              ) : celebrityId ? (
                'Update'
              ) : (
                'Submit'
              )}
            </button>
            {isAdmin && !!celebrityId && (
              <button
                className="btn btn-outline-danger"
                onClick={toggleDeleteDialog}
                type="button"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
      <DeleteDialog
        isOpen={isOpen}
        onDelete={handleDelete}
        onToggle={toggleDeleteDialog}
        itemName={fullname}
      />
    </div>
  );
}

class DatePickerInput extends React.Component {
  render() {
    return (
      <input
        type="text"
        className="form-control"
        id="birthdate"
        name="birthdate"
        placeholder={`${
          this.props.isSmallScreen ? 'Tap' : 'Click'
        } to select a date`}
        onClick={this.props.onClick}
        value={this.props.value}
        ref={el => this.props.inputRef && this.props.inputRef(el)}
        onChange={() => {}}
        readOnly
      />
    );
  }
}
