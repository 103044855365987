import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default function useScrollTop() {
  const history = useHistory();
  useEffect(() => {
    if (history.action !== 'POP') {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [history, history.action, history.location.pathname]);
}
