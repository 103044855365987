import { getFavorites, getAuthUser, getLastCelebrity } from '../reducers';

export const setCurrentField = field => ({
  type: 'SET_CURRENT_FIELD',
  field,
});

export const fetchFields = () => (dispatch, getState, api) => {
  dispatch({ type: 'FETCH_FIELDS_REQUEST' });
  return api
    .metadata('fields')
    .get()
    .then(
      doc =>
        dispatch({
          type: 'FETCH_FIELDS_SUCCESS',
          doc,
        }),
      error =>
        dispatch({
          type: 'FETCH_FIELDS_FAILURE',
          error,
        })
    );
};

export const addField = (key, value) => (dispatch, getState, api) => {
  dispatch({ type: 'ADD_FIELD_REQUEST' });
  const field = { [key]: value };
  return api
    .addFields(field)
    .then(() => dispatch({ type: 'ADD_FIELD_SUCCESS', field }))
    .catch(error => dispatch({ type: 'ADD_FIELD_FAILURE', error }));
};

export const deleteField = key => (dispatch, getState, api) => {
  dispatch({ type: 'DELETE_FIELD_REQUEST' });
  const field = { [key]: api.FieldValue.delete() };
  return api
    .updateFields(field)
    .then(() => dispatch({ type: 'DELETE_FIELD_SUCCESS', field }))
    .catch(error => dispatch({ type: 'DELETE_FIELD_FAILURE', error }));
};

export const setCurrentCountry = country => ({
  type: 'SET_CURRENT_COUNTRY',
  country,
});

export const fetchCelebrityIdsByCountry = () => (dispatch, getState, api) => {
  dispatch({ type: 'FETCH_CELEBRITY_IDS_BY_COUNTRY_REQUEST' });
  return api
    .metadata('celebrityIdsByCountry')
    .get()
    .then(
      doc =>
        dispatch({
          type: 'FETCH_CELEBRITY_IDS_BY_COUNTRY_SUCCESS',
          doc,
        }),
      error =>
        dispatch({
          type: 'FETCH_CELEBRITY_IDS_BY_COUNTRY_FAILURE',
          error,
        })
    );
};

export const fetchCelebrityIdsByField = () => (dispatch, getState, api) => {
  dispatch({ type: 'FETCH_CELEBRITY_IDS_BY_FIELD_REQUEST' });
  return api
    .metadata('celebrityIdsByField')
    .get()
    .then(
      doc =>
        dispatch({
          type: 'FETCH_CELEBRITY_IDS_BY_FIELD_SUCCESS',
          doc,
        }),
      error =>
        dispatch({
          type: 'FETCH_CELEBRITY_IDS_BY_FIELD_FAILURE',
          error,
        })
    );
};

// TODO: fix: two times calling
export const fetchCelebrities = query => (dispatch, getState, api) => {
  dispatch({ type: 'FETCH_CELEBRITIES_REQUEST', query });
  if (query.ids) {
    return Promise.all(query.ids.map(id => api.celebrity(id).get())).then(
      docs =>
        dispatch({
          type: 'FETCH_CELEBRITIES_SUCCESS',
          query,
          docs,
        }),
      error =>
        dispatch({
          type: 'FETCH_CELEBRITIES_FAILURE',
          query,
          error,
        })
    );
  }
  return api.fetchCelebrities(query).then(
    snapshot =>
      dispatch({
        type: 'FETCH_CELEBRITIES_SUCCESS',
        query,
        docs: snapshot.docs,
      }),
    error =>
      dispatch({
        type: 'FETCH_CELEBRITIES_FAILURE',
        query,
        error,
      })
  );
};

export const fetchMoreCelebrities = query => (dispatch, getState, api) => {
  const newQuery = {
    ...query,
    startAfter: getLastCelebrity(getState(), query.key),
  };
  return fetchCelebrities(newQuery)(dispatch, getState, api);
};

export const fetchCelebrity = id => (dispatch, getState, api) => {
  dispatch({ type: 'FETCH_CELEBRITY_REQUEST' });
  return api
    .celebrity(id)
    .get()
    .then(
      doc =>
        dispatch({
          type: 'FETCH_CELEBRITY_SUCCESS',
          doc,
        }),
      error =>
        dispatch({
          type: 'FETCH_CELEBRITY_FAILURE',
          error,
        })
    );
};

export const deleteCelebrity = id => (dispatch, getState, api) => {
  dispatch({ type: 'DELETE_CELEBRITY_REQUEST' });
  return api
    .celebrity(id)
    .delete()
    .then(() => {
      dispatch({
        type: 'DELETE_CELEBRITY_SUCCESS',
        id,
      });
      return Promise.resolve();
    })
    .catch(error => {
      dispatch({
        type: 'DELETE_CELEBRITY_FAILURE',
        error,
      });
      return Promise.reject(error);
    });
};

export const vote = (field, country, winner) => (dispatch, getState, api) => {
  dispatch({ type: 'VOTE_REQUEST' });
  return api
    .vote(field, country, winner)
    .then(result => dispatch({ type: 'VOTE_SUCCESS', result }))
    .catch(error => {
      dispatch({ type: 'VOTE_FAILURE', error });
      return Promise.reject(error);
    });
};

export const setAuthUser = authUser => ({
  type: 'SET_AUTH_USER',
  authUser,
});

export const updatePassword = password => (dispatch, getState, api) => {
  dispatch({ type: 'UPDATE_PASSWORD_REQUEST' });
  return api
    .updatePassword(password)
    .then(() => dispatch({ type: 'UPDATE_PASSWORD_SUCCESS' }))
    .catch(error => {
      dispatch({ type: 'UPDATE_PASSWORD_FAILURE', error });
      return Promise.reject(error);
    });
};

export const forgetPassword = email => (dispatch, getState, api) => {
  dispatch({ type: 'FORGET_PASSWORD_REQUEST' });
  return api
    .sendPasswordResetEmail(email)
    .then(() => dispatch({ type: 'FORGET_PASSWORD_SUCCESS' }))
    .catch(error => {
      dispatch({ type: 'FORGET_PASSWORD_FAILURE', error });
      return Promise.reject(error);
    });
};

export const setProfile = profile => ({
  type: 'SET_PROFILE',
  profile,
});

export const refreshProfile = () => (dispatch, getState, api) => {
  let authUser = getAuthUser(getState());
  return api.getUser(authUser.uid).then(user => {
    return dispatch(setProfile(user));
  });
};

export const updateProfile = profile => (dispatch, getState, api) => {
  dispatch({ type: 'UPDATE_PROFILE_REQUEST' });
  return api
    .updateProfile(profile)
    .then(() => dispatch({ type: 'UPDATE_PROFILE_SUCCESS', profile }))
    .catch(error => {
      dispatch({ type: 'UPDATE_PROFILE_FAILURE', error });
      return Promise.reject(error);
    });
};

export const addFavorite = celebrityId => (dispatch, getState, api) => {
  const favorites = getFavorites(getState());
  if (favorites.includes(celebrityId)) {
    return Promise.resolve();
  }
  dispatch({ type: 'ADD_FAVORITE_REQUEST' });
  const newFavorites = [...favorites, celebrityId];
  return dispatch(updateProfile({ favorites: newFavorites }))
    .then(() =>
      dispatch({
        type: 'ADD_FAVORITE_SUCCESS',
        celebrityId,
      })
    )
    .catch(error => {
      dispatch({ type: 'ADD_FAVORITE_FAILURE' });
      return Promise.reject(error);
    });
};

export const removeFavorite = celebrityId => (dispatch, getState, api) => {
  const favorites = getFavorites(getState());
  if (!favorites.includes(celebrityId)) {
    return Promise.resolve();
  }
  dispatch({ type: 'REMOVE_FAVORITE_REQUEST' });
  const newFavorites = favorites.filter(f => f !== celebrityId);
  return dispatch(updateProfile({ favorites: newFavorites }))
    .then(() =>
      dispatch({
        type: 'REMOVE_FAVORITE_SUCCESS',
        celebrityId,
      })
    )
    .catch(error => {
      dispatch({ type: 'REMOVE_FAVORITE_FAILURE' });
      return Promise.reject(error);
    });
};

export const signUp = user => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_UP_REQUEST' });
  return api.signUp(user).then(
    authUser => {
      dispatch({ type: 'SIGN_UP_SUCCESS', authUser });
      return Promise.resolve();
    },
    error => {
      dispatch({
        type: 'SIGN_UP_FAILURE',
        error,
      });
      return Promise.reject(error);
    }
  );
};

export const signIn = (email, password) => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_IN_REQUEST' });
  return api.signInWithEmailAndPassword(email, password).then(
    authUser => {
      dispatch({
        type: 'SIGN_IN_SUCCESS',
        authUser,
      });
      return Promise.resolve(true);
    },
    error => {
      dispatch({
        type: 'SIGN_IN_FAILURE',
        error,
      });
      return Promise.resolve(false);
    }
  );
};

export const signInWithProvider = provider => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_IN_REQUEST' });
  let response;
  switch (provider) {
    case 'google':
      response = api.signInWithGoogle();
      break;
    case 'facebook':
      response = api.signInWithFacebook();
      break;
    case 'twitter':
      response = api.signInWithTwitter();
      break;
    default:
      return Promise.resolve(false);
  }
  return response.then(
    result => {
      dispatch({
        type: 'SIGN_IN_SUCCESS',
        authUser: result.user,
      });
      return Promise.resolve(true);
    },
    error => {
      dispatch({
        type: 'SIGN_IN_FAILURE',
        error,
      });
      return Promise.resolve(false);
    }
  );
};

export const signOut = () => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_OUT_REQUEST' });
  return api.signOut().then(
    () =>
      dispatch({
        type: 'SIGN_OUT_SUCCESS',
      }),
    error =>
      dispatch({
        type: 'SIGN_OUT_FAILURE',
        error,
      })
  );
};

export const deleteAccount = () => async (dispatch, getState, api) => {
  try {
    dispatch({ type: 'DELETE_ACCOUNT_REQUEST' });
    await api.deleteAccount();
    return dispatch({
      type: 'DELETE_ACCOUNT_SUCCESS',
    });
  } catch (error) {
    dispatch({
      type: 'DELETE_ACCOUNT_FAILURE',
      error,
    });
    return Promise.reject(error);
  }
};
