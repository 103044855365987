import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import * as ROUTES from '../constants/routes';
import { useFormInput, useRouter } from '../hooks';
import { forgetPassword } from '../actions';

export default function PasswordForget() {
  const dispatch = useDispatch();
  const { history } = useRouter();
  const { addToast } = useToasts();
  const email = useFormInput('');
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    setIsFetching(true);
    dispatch(forgetPassword(email.value))
      .then(() => {
        setIsFetching(false);
        addToast('A password reset e-mail was send.', {
          appearance: 'success',
          autoDismiss: true,
        });
        history.push(ROUTES.HOME);
      })
      .catch(error => {
        setIsFetching(false);
        setError(error);
      });
    event.preventDefault();
  }

  const isInvalid = email.value === '';

  return (
    <div className="container">
      <form className="form-auth" onSubmit={handleSubmit} autoComplete="off">
        <div className="text-center mb-4">
          <h1 className="h3 mb-3 font-weight-normal">Password Forget</h1>
        </div>
        <div className="form-label-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            required
            autoFocus
            autoCapitalize="off"
            autoCorrect="off"
            name="email"
            {...email}
          />
          <label htmlFor="inputEmail">Email address</label>
        </div>
        <button
          disabled={isInvalid}
          className="btn btn-primary btn-block"
          type="submit"
        >
          {isFetching ? (
            <>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            'Reset Password'
          )}
        </button>
        <div className="text-center">
          {!isFetching && error && (
            <p className="mt-2 text-danger">{error.message}</p>
          )}
        </div>
      </form>
    </div>
  );
}
