import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import * as ROUTES from '../constants/routes';
import { useRouter } from './';
import { getAuthUser, getProfile } from '../reducers';

function isSignedIn(authUser, profile) {
  return !!authUser;
}

function isVerified(authUser, profile) {
  if (!isSignedIn(authUser, profile)) {
    return false;
  }
  const providerIds = authUser.providerData
    .map(provider => provider.providerId)
    .filter(providerId => providerId !== 'password');
  return !!providerIds.length || authUser.emailVerified;
}

function isAdmin(authUser, profile) {
  return (
    isSignedIn(authUser, profile) && profile.role && profile.role === 'admin'
  );
}

export default function useAuthorization(condition, message, redirect = true) {
  const { addToast } = useToasts();
  const router = useRouter();
  const authUser = useSelector(getAuthUser);
  const profile = useSelector(getProfile);

  const permitted = condition(authUser, profile);

  useEffect(() => {
    if (redirect && !permitted) {
      addToast(message, { appearance: 'warning', autoDismiss: true });
      if (!isSignedIn(authUser, profile)) {
        router.history.push(ROUTES.SIGNIN);
      } else {
        router.history.push(ROUTES.HOME);
      }
    }
  }, [router, permitted, addToast, message, redirect, authUser, profile]);

  return permitted;
}

export function useSignInAuthorization(redirect = true) {
  return useAuthorization(
    isSignedIn,
    'You must sign in to see this page.',
    redirect
  );
}

export function useVerifiedEmailAuthorization(redirect = false) {
  useAuthorization(isSignedIn, 'You must sign in to see this page.', true);
  return useAuthorization(
    isVerified,
    'You must verify your email address.',
    redirect
  );
}

export function useAdminAuthorization(redirect = true) {
  return useAuthorization(
    isAdmin,
    'You must be an admin to see this page.',
    redirect
  );
}
