import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { PolicyText } from './Info';
import * as ROUTES from '../constants/routes';
import { useFormInput, useRouter } from '../hooks';
import { signIn, signInWithProvider } from '../actions';
import { getSignInError, getSignInFetching } from '../reducers';
import Icon from './Icon';

function SignInSocial({ provider, onSuccess, children }) {
  const dispatch = useDispatch();
  async function handleClick(event) {
    event.preventDefault();
    const result = await dispatch(signInWithProvider(provider));
    if (result) {
      onSuccess();
    }
  }

  return (
    <button className={`btn btn-${provider}`} onClick={handleClick}>
      <Icon icon={['fab', provider]} fixedWidth />
      {children}
    </button>
  );
}

function useSuccess() {
  const { history, location } = useRouter();
  return () => {
    const query = location.search.split('=');
    let redirectUrl;
    if (query.length === 2 && query[0] === '?redirect') {
      redirectUrl = query[1];
    }
    history.push(redirectUrl || ROUTES.VOTE);
  };
}

export function SocialSignInButtons() {
  const handleSuccess = useSuccess();
  return (
    <>
      <SignInSocial provider="google" onSuccess={handleSuccess}>
        Sign in with Google
      </SignInSocial>
      <SignInSocial provider="facebook" onSuccess={handleSuccess}>
        Sign in with Facebook
      </SignInSocial>
      <SignInSocial provider="twitter" onSuccess={handleSuccess}>
        Sign in with Twitter
      </SignInSocial>
    </>
  );
}

export default function SignIn() {
  const email = useFormInput('');
  const password = useFormInput('');

  const error = useSelector(getSignInError);
  const errorMessage =
    error &&
    (error.code === 'auth/too-many-requests'
      ? 'Too many unsuccessful login attempts. Please try again later'
      : error.message);
  const isFetching = useSelector(getSignInFetching);

  const dispatch = useDispatch();
  const handleSuccess = useSuccess();
  function handleSubmit(event) {
    event.preventDefault();
    dispatch(signIn(email.value, password.value)).then(result => {
      if (result) {
        handleSuccess();
      }
    });
  }

  const isInvalid = password.value === '' || email.value === '';

  return (
    <div className="container">
      <form className="form-auth" onSubmit={handleSubmit} autoComplete="off">
        <div className="text-center mb-4">
          <h1 className="h3 mb-3 font-weight-normal">Sign In</h1>
        </div>
        <div className="form-label-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            required
            autoFocus
            autoCapitalize="off"
            autoCorrect="off"
            name="email"
            {...email}
          />
          <label htmlFor="inputEmail">Email address</label>
        </div>
        <div className="form-label-group">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            required
            name="password"
            {...password}
          />
          <label htmlFor="inputPassword">Password</label>
        </div>
        <button
          disabled={isInvalid}
          className="btn btn-primary btn-block"
          type="submit"
        >
          {isFetching ? (
            <>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            'Sign in'
          )}
        </button>
        <div className="text-center">
          <div className="mt-2">
            <Link to={ROUTES.PASSWORD_FORGET}>Forget password?</Link>
          </div>
          {!isFetching && error && (
            <p className="mt-2 text-danger">{errorMessage}</p>
          )}
          <div className="text-center my-3">-or-</div>
          <SocialSignInButtons />
          <PolicyText />
        </div>
      </form>
    </div>
  );
}
