import { combineReducers } from 'redux';
import fields, * as fromFields from './fields';
import { overwrite, getName } from 'country-list';

overwrite([
  { code: 'KR', name: 'South Korea' },
  { code: 'KP', name: 'North Korea' },
  { code: 'GB', name: 'United Kingdom' },
]);

const country = (state = 'INTL', action) => {
  switch (action.type) {
    case 'SET_CURRENT_COUNTRY':
      return action.country;
    default:
      return state;
  }
};

const celebrityIds = (state = { byCountry: {}, byField: {} }, action) => {
  switch (action.type) {
    case 'FETCH_CELEBRITY_IDS_BY_COUNTRY_SUCCESS':
      return { ...state, byCountry: action.doc.data() };
    case 'FETCH_CELEBRITY_IDS_BY_FIELD_SUCCESS':
      return { ...state, byField: action.doc.data() };
    default:
      return state;
  }
};

export default combineReducers({ country, celebrityIds, fields });

export const getCurrentCountry = state => state.country;

export const getCurrentField = state =>
  fromFields.getCurrentField(state.fields);
export const getFields = state => fromFields.getFields(state.fields);

export const getVoteableCountries = state => {
  const byCountry = state.celebrityIds.byCountry;
  const countries = Object.keys(byCountry)
    .filter(country => {
      const byField = byCountry[country];
      for (const field in byField) {
        const byLevel = byField[field];
        for (const level in byLevel) {
          if (byLevel[level].length > 1) {
            return true;
          }
        }
      }
      return false;
    })
    .map(code => ({ value: code, label: getName(code) }));
  countries.unshift({ value: 'INTL', label: 'International' });
  return countries;
};

export const getVoteableFields = state => {
  const country = getCurrentCountry(state);
  const fields = getFields(state);
  const byField =
    country === 'INTL'
      ? state.celebrityIds.byField
      : state.celebrityIds.byCountry[country] || {};
  return Object.keys(byField)
    .filter(field => {
      const byLevel = byField[field];
      for (const level in byLevel) {
        if (byLevel[level].length > 1) {
          return true;
        }
      }
      return false;
    })
    .map(field => ({ value: field, label: fields[field] }));
};

export const getCountriesForRatings = state => {
  const byCountry = state.celebrityIds.byCountry;
  const countries = Object.keys(byCountry)
    .filter(country => {
      const byField = byCountry[country];
      for (const field in byField) {
        const byLevel = byField[field];
        for (const level in byLevel) {
          if (byLevel[level].length > 0) {
            return true;
          }
        }
      }
      return false;
    })
    .map(code => ({ value: code, label: getName(code) }));
  countries.unshift({ value: 'INTL', label: 'International' });
  return countries;
};

export const getFieldsForRatings = state => {
  const country = getCurrentCountry(state);
  const fields = getFields(state);
  const byField =
    country === 'INTL'
      ? state.celebrityIds.byField
      : state.celebrityIds.byCountry[country] || {};
  return Object.keys(byField)
    .filter(field => {
      const byLevel = byField[field];
      for (const level in byLevel) {
        if (byLevel[level].length > 0) {
          return true;
        }
      }
      return false;
    })
    .map(field => ({ value: field, label: fields[field] }));
};
