const INITIAL_STATE = {
  user: null,
  signInFetching: false,
  signInError: null,
  signUpFetching: false,
  signUpError: null,
};

const authUser = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_AUTH_USER':
      return {
        ...state,
        user: action.authUser,
      };
    case 'UNSET_AUTH_USER':
      return {
        ...state,
        user: null,
      };
    case 'SIGN_IN_REQUEST':
      return {
        ...state,
        signInFetching: true,
        signInError: null,
      };
    case 'SIGN_IN_SUCCESS':
      return {
        ...state,
        user: action.authUser,
        signInFetching: false,
        signInError: null,
      };
    case 'SIGN_IN_FAILURE':
      return {
        ...state,
        signInFetching: false,
        signInError: action.error,
      };
    case 'SIGN_UP_REQUEST':
      return {
        ...state,
        signUpFetching: true,
        signUpError: null,
      };
    case 'SIGN_UP_SUCCESS':
      return {
        ...state,
        user: action.authUser,
        signUpFetching: false,
        signUpError: null,
      };
    case 'SIGN_UP_FAILURE':
      return {
        ...state,
        signUpFetching: false,
        signUpError: action.error,
      };
    default:
      return state;
  }
};

export default authUser;

export const getAuthUser = state => state.user;
export const getSignInError = state => state.signInError;
export const getSignInFetching = state => state.signInFetching;
export const getSignUpError = state => state.signUpError;
export const getSignUpFetching = state => state.signUpFetching;
