import React from 'react';

export default function TermsOfService() {
  return (
    <div className="container">
      <h2>Thank you for using Reputelo!</h2>
      <p>
        These Terms of Service ("Terms") govern your access to and use of the
        Reputelo website, apps, APIs, and widgets (“Reputelo” or the “Service”).
        Please read these Terms carefully, and contact us if you have any
        questions. By accessing or using Reputelo, you agree to be bound by
        these Terms, our Privacy Policy, our Cookies Policy.
      </p>
      <h2>1. Our service</h2>
      <p>Some of the things we show you are promoted by advertisers.</p>
      <h2>2. Using Reputelo</h2>
      <h3>a. Who can use Reputelo</h3>
      <p>
        You may use Reputelo only if you can legally form a binding contract
        with Reputelo, and only in compliance with these Terms and all
        applicable laws. When you create your Reputelo account, you must provide
        us with accurate and complete information. You can’t use Reputelo if it
        would be prohibited by U.S. sanctions. Using Reputelo may include
        downloading software to your computer, phone, tablet, or other device.
        You agree that we may automatically update that software, and these
        Terms will apply to any updates.
      </p>
      <h3>b. Our license to you</h3>
      <p>
        Subject to these Terms and our policies, we grant you a limited,
        non-exclusive, non-transferable, and revocable license to use our
        Service.
      </p>
      <h2>3. Your content</h2>
      <h3>a. Suggesting the celebrity</h3>
      <p>
        Reputelo allows you to suggest the celebrity, including photo,
        firstname, lastname, birthdate, country, profiles, fields information.
        Thing that you suggest on Reputelo is referred to as "User Content." You
        are solely responsible for, the User Content you suggest to Reputelo.
      </p>
      <h3>b. How Reputelo and other users can use your “User Content”</h3>
      <p>
        You grant Reputelo and our users a non-exclusive, royalty-free,
        transferable, sub-licensable, worldwide license to use, store, display,
        reproduce, save, modify, create derivative works, perform, and
        distribute your “User Content” on Reputelo solely for the purposes of
        operating, developing, providing, and using Reputelo. Nothing in these
        Terms restricts other legal rights Reputelo may have to “User Content”,
        for example under other licenses. We reserve the right to remove or
        modify “User Content”, or change the way it’s used in Reputelo, for any
        reason. This includes “User Content” that we believe violates these
        Terms or any other policies.
      </p>
      <h3>c. How long we keep your “User Content”</h3>
      <p>
        Following termination or deactivation of your account, or if you remove
        any “User Content” from Reputelo, we may keep your “User Content” for a
        reasonable period of time for backup, archival, or audit purposes. But
        we don’t remove the approved celebrities you suggested. Reputelo and its
        users may retain and continue to use, store, display, reproduce, modify,
        create derivative works, perform, and distribute any of your “User
        Content” that other users have suggested on Reputelo.
      </p>
      <h3>d. Feedback you provide</h3>
      <p>
        We value hearing from our users, and are always interested in learning
        about ways we can make Reputelo more awesome. If you choose to submit
        comments, ideas or feedback, you agree that we are free to use them
        without any restriction or compensation to you. By accepting your
        submission, Reputelo doesn’t waive any rights to use similar or related
        feedback previously known to Reputelo, or developed by its employees, or
        obtained from sources other than you.
      </p>
      <h2>4. Copyright policy</h2>
      <p>
        Reputelo and its users accept applicable copyright laws. We respect
        copyright.
      </p>
      <h2>5. Security</h2>
      <p>
        We care about the security of our users. While we work to protect the
        security of your “User Content” and account, Reputelo can’t guarantee
        that unauthorized third parties won’t be able to defeat our security
        measures. We ask that you keep your password secure. Please notify us
        immediately of any compromise or unauthorized use of your account.
      </p>
      <h2>6. Third party links, sites and services</h2>
      <p>
        Reputelo may contain links to third party websites, advertisers,
        services, special offers, or other events or activities that are not
        owned or controlled by Reputelo. We don’t endorse or assume any
        responsibility for any such third party sites, information, materials,
        products, or services. If you access any third party website, service,
        or content from Reputelo, you do so at your own risk and you agree that
        Reputelo has no liability arising from your use of or access to any
        third party website, service, or content.
      </p>
      <h2>7. Termination</h2>
      <p>
        Reputelo may terminate your right to access or use Reputelo for any
        reason on appropriate notice. We may terminate your access immediately
        and without notice if we have a good reason. Upon termination, you
        continue to be bound by Sections 3 of these Terms.
      </p>
      <h2>8. Disclaimers</h2>
      <p>
        Reputelo specifically disclaims any and all warranties and conditions of
        merchantability, fitness for a particular purpose, and non-infringement,
        and any warranties arising out of course of dealing or usage of trade.
      </p>
      <h2>9. Dispute</h2>
      <p>
        For any dispute you have with Reputelo, you agree to first contact us
        and try to resolve the dispute with us informally. If we need to contact
        you, we will do so at the email address on your Reputelo account.
      </p>
      <h2>10. General terms</h2>
      <h3>Notification procedures and changes to these Terms</h3>
      <p>
        We reserve the right to determine the form and means of providing
        notifications to you, and you agree to receive legal notices
        electronically if that’s what we decide. We may revise these Terms from
        time to time and the most current version will always be posted on
        Reputelo. If a revision, in our discretion, is material, we’ll notify
        you. By continuing to access or use Reputelo after revisions become
        effective, you agree to be bound by the new Terms. If you don’t agree to
        the new terms, please stop using Reputelo.
      </p>
      <h3>Assignment</h3>
      <p>
        These Terms, and any rights and licenses granted hereunder, may not be
        transferred or assigned by you, but may be assigned by Reputelo without
        restriction. Any attempted transfer or assignment in violation hereof
        shall be null and void.
      </p>
      <h3>Entire agreement/severability</h3>
      <p>
        These Terms, together with the Privacy Policy and any amendments and any
        additional agreements you may enter into with Reputelo shall constitute
        the entire agreement between you and Reputelo concerning the Service. If
        any provision of these Terms is deemed invalid, then that provision will
        be limited or eliminated to the minimum extent necessary, and the
        remaining provisions of these Terms will remain in full force and
        effect.
      </p>
      <h3>No waiver</h3>
      <p>
        No waiver of any term of these Terms shall be deemed a further or
        continuing waiver of such term or any other term, and Reputelo's failure
        to assert any right or provision under these Terms shall not constitute
        a waiver of such right or provision.
      </p>
      <p>Effective date: November 1, 2019</p>
    </div>
  );
}
