import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PolicyText } from './Info';
import { SocialSignInButtons } from './SignIn';
import * as ROUTES from '../constants/routes';
import { useFormInput, useRouter } from '../hooks';
import { signUp } from '../actions';
import { getSignUpError, getSignUpFetching } from '../reducers';

export default function SignUp() {
  const { history } = useRouter();
  const displayName = useFormInput('');
  const email = useFormInput('');
  const passwordOne = useFormInput('');
  const passwordTwo = useFormInput('');

  const error = useSelector(getSignUpError);
  const isFetching = useSelector(getSignUpFetching);

  const dispatch = useDispatch();
  function handleSubmit(event) {
    event.preventDefault();
    const user = {
      email: email.value,
      password: passwordOne.value,
      displayName: displayName.value,
    };
    dispatch(signUp(user)).then(() => {
      history.push(ROUTES.VOTE);
    });
  }

  const isInvalid =
    passwordOne.value !== passwordTwo.value ||
    passwordOne.value === '' ||
    email.value === '' ||
    displayName.value === '';

  return (
    <div className="container">
      <form className="form-auth" onSubmit={handleSubmit} autoComplete="off">
        <div className="text-center mb-4">
          <h1 className="h3 mb-3 font-weight-normal">Sign Up</h1>
        </div>
        <div className="form-label-group">
          <input
            type="text"
            className="form-control"
            placeholder="Display name"
            required
            autoFocus
            name="displayName"
            {...displayName}
          />
          <label htmlFor="inputEmail">Display name</label>
        </div>
        <div className="form-label-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            required
            autoCapitalize="off"
            autoCorrect="off"
            name="email"
            {...email}
          />
          <label htmlFor="inputEmail">Email address</label>
        </div>
        <div className="form-label-group">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            required
            name="passwordOne"
            {...passwordOne}
          />
          <label htmlFor="inputPassword">Password</label>
        </div>
        <div className="form-label-group">
          <input
            type="password"
            className="form-control"
            placeholder="Confirm password"
            required
            name="passwordTwo"
            {...passwordTwo}
          />
          <label htmlFor="inputPassword">Confirm password</label>
        </div>
        <button
          disabled={isInvalid}
          className="btn btn-primary btn-block"
          type="submit"
        >
          {isFetching ? (
            <>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            'Sign up'
          )}
        </button>
        <div className="text-center">
          {!isFetching && error && (
            <p className="mt-2 text-danger">{error.message}</p>
          )}
          <div className="text-center my-3">-or-</div>
          <SocialSignInButtons />
          <PolicyText />
        </div>
      </form>
    </div>
  );
}
