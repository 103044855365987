import { useDispatch } from 'react-redux';

import { useRouter } from '../hooks';
import * as ROUTES from '../constants/routes';
import { signOut } from '../actions';

export default function SignOut() {
  const dispatch = useDispatch();
  const { history } = useRouter();
  dispatch(signOut()).then(() => {
    history.push(ROUTES.HOME);
  });
  return null;
}
