export const HOME = '/';
export const VOTE = '/vote';
export const RATINGS = '/ratings';
export const ACCOUNT = '/account';
export const ACCOUNT_SETTINGS = ACCOUNT + '/settings';
export const ACCOUNT_DELETE = ACCOUNT + '/delete';
export const ACCOUNT_FAVORITES = ACCOUNT + '/favorites';
export const ACCOUNT_PASSWORD_CHANGE = ACCOUNT + '/password-change';
export const ACCOUNT_MY_SUGGESTIONS = ACCOUNT + '/my-suggestions';
export const SIGNUP = '/signup';
export const SIGNIN = '/signin';
export const SIGNOUT = '/signout';
export const PASSWORD_FORGET = '/password-forget';
export const CELEBRITY_PROFILE = '/celebrity-profile';
export const CELEBRITY_SUGGEST = '/celebrity-suggest';
export const CELEBRITY_COMPARE = '/celebrity-compare';
export const ADMIN = '/admin';
export const ADMIN_CELEBRITY_LIST = ADMIN + '/celebrity/list';
export const ADMIN_CELEBRITY_DETAIL = ADMIN + '/celebrity/detail';
export const ADMIN_FIELD_LIST = ADMIN + '/field/list';
export const ABOUT = '/about';
export const CONTACT = '/contact-us';
export const TOS = '/terms-of-service';
export const PP = '/privacy-policy';
export const CU = '/cookie-use';
