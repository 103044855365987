import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useFavorites, useCelebrityQuery } from '../../hooks';
import { celebrityProfileUrl } from '../../helpers';
import Icon from '../Icon';

export default function Favorites() {
  const { favorites, removeFavorite } = useFavorites();

  const query = useMemo(
    () => ({
      ids: favorites,
      key: 'favorites',
    }),
    [favorites]
  );
  const { celebrities, isFetching, error } = useCelebrityQuery(query);

  return (
    <div className="container">
      <div className="row my-3">
        {isFetching ? (
          <div className="col-12 text-center">
            <div
              className="spinner-grow spinner-grow-lg text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : error ? (
          <div className="col-12 text-center">
            <span className="text-danger">{error.message}</span>
          </div>
        ) : !celebrities.length ? (
          <div
            className="col-12 text-center d-flex align-items-center justify-content-center"
            style={{ minHeight: '350px' }}
          >
            <span className="text-primary">No favorites.</span>
          </div>
        ) : (
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <Celebrities
                    celebrities={celebrities}
                    onRemoveClick={removeFavorite}
                  />
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Celebrities({ celebrities, onRemoveClick }) {
  return celebrities.map((celebrity, idx) => (
    <tr key={celebrity.id}>
      <th className="align-middle" scope="row">
        {idx + 1}
      </th>
      <td className="align-middle">
        <Link to={celebrityProfileUrl(celebrity.id, celebrity)}>
          {celebrity.firstname} {celebrity.lastname}
        </Link>
      </td>
      <td className="align-middle">
        <button
          className="btn"
          onClick={() => onRemoveClick(celebrity)}
          title="Remove from your favorites"
        >
          <Icon
            icon="heart-broken"
            fixedWidth
            className="text-danger"
            size="lg"
          />
        </button>
      </td>
    </tr>
  ));
}
