import { combineReducers } from 'redux';

const currentField = (state = 'singer', action) => {
  switch (action.type) {
    case 'SET_CURRENT_FIELD':
      return action.field;
    default:
      return state;
  }
};

const byKey = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_FIELDS_SUCCESS':
      return action.doc.exists ? action.doc.data() : state;
    case 'ADD_FIELD_SUCCESS':
      return { ...state, ...action.field };
    case 'DELETE_FIELD_SUCCESS':
      const nextState = { ...state };
      Object.keys(action.field).forEach(key => delete nextState[key]);
      return nextState;
    default:
      return state;
  }
};

export default combineReducers({ currentField, byKey });

export const getCurrentField = state => state.currentField;
export const getFields = state => state.byKey;
