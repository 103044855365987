import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { useSignInAuthorization, useAdminAuthorization } from '../hooks';
import { celebrityProfileUrl } from '../helpers';
import Search from './Search';
import Icon from './Icon';

export default function Header() {
  const isSignedIn = useSignInAuthorization(false);
  const isAdmin = useAdminAuthorization(false);

  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-secondary">
        <div className="container">
          <Link
            className="navbar-brand mr-auto mr-lg-0"
            to={ROUTES.HOME}
            style={{ fontFamily: 'Marck Script', fontWeight: 'bold' }}
          >
            Reputelo
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#menu"
          >
            <Icon className="text-primary" icon="bars" fixedWidth />
          </button>
          <div className="collapse navbar-collapse" id="menu">
            <ul className="navbar-nav">
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#menu"
              >
                <Link className="nav-link" to={ROUTES.CELEBRITY_SUGGEST}>
                  Suggest Celebrity
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#menu"
              >
                <Link className="nav-link" to={ROUTES.VOTE}>
                  Vote
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#menu"
              >
                <Link className="nav-link" to={ROUTES.RATINGS}>
                  Ratings &amp; Rankings
                </Link>
              </li>
            </ul>
            <div className="mx-auto">
              <Search createUrl={celebrityProfileUrl} />
            </div>
            <ul
              className="navbar-nav"
              data-toggle="collapse"
              data-target="#menu"
            >
              {isAdmin && (
                <li
                  className="nav-item"
                  data-toggle="collapse"
                  data-target="#menu"
                >
                  <Link className="nav-link" to={ROUTES.ADMIN}>
                    Admin
                  </Link>
                </li>
              )}
              {isSignedIn && (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    id="accountDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    to="#"
                  >
                    Account
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="accountDropdown"
                  >
                    <Link
                      className="dropdown-item"
                      to={ROUTES.ACCOUNT_SETTINGS}
                    >
                      Settings
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={ROUTES.ACCOUNT_FAVORITES}
                    >
                      Favorites
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={ROUTES.ACCOUNT_MY_SUGGESTIONS}
                    >
                      My Suggestions
                    </Link>
                    <Link className="dropdown-item" to={ROUTES.SIGNOUT}>
                      Sign out
                    </Link>
                  </div>
                </li>
              )}
              {!isSignedIn && (
                <li className="nav-item">
                  <Link className="nav-link" to={ROUTES.SIGNIN}>
                    Sign in
                  </Link>
                </li>
              )}
            </ul>
            {!isSignedIn && (
              <Link
                className="btn btn-outline-primary my-2 my-sm-0"
                to={ROUTES.SIGNUP}
              >
                Sign up
              </Link>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}
