import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentField, setCurrentCountry } from '../../actions';
import {
  getCurrentField,
  getCurrentCountry,
  getCountriesForRatings,
  getFieldsForRatings,
} from '../../reducers';
import CurrentRating from './CurrentRating';
import AnnualRanking from './AnnualRanking';

export default function Ratings() {
  const field = useSelector(getCurrentField);
  const country = useSelector(getCurrentCountry);
  const countries = useSelector(getCountriesForRatings);
  const fields = useSelector(getFieldsForRatings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      countries.length !== 0 &&
      !countries.map(c => c.value).includes(country)
    ) {
      dispatch(setCurrentCountry('INTL'));
    }
  }, [country, countries, dispatch]);

  useEffect(() => {
    if (fields.length !== 0 && !fields.map(f => f.value).includes(field)) {
      dispatch(setCurrentField(fields[0].value));
    }
  }, [field, fields, dispatch]);

  function handleFieldChange(event) {
    dispatch(setCurrentField(event.target.value));
  }

  function handleCountryChange(event) {
    dispatch(setCurrentCountry(event.target.value));
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-auto">
          <h1 className="text-dark">Ratings &amp; Rankings</h1>
        </div>
        <div className="col"></div>
        <div className="col-auto d-flex justify-content-end">
          <form className="form-inline">
            <label className="sr-only" htmlFor="country">
              Country
            </label>
            <select
              className="form-control mr-sm-2 mb-2 mb-sm-0"
              id="country"
              name="country"
              onChange={handleCountryChange}
              value={country}
            >
              {countries.map(country => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </select>
            <label className="sr-only" htmlFor="field">
              Field
            </label>
            <select
              className="form-control"
              id="field"
              name="field"
              onChange={handleFieldChange}
              value={field}
            >
              {fields.map(field => (
                <option key={field.value} value={field.value}>
                  {field.label}
                </option>
              ))}
            </select>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <CurrentRating />
        </div>
        <div className="col-lg-6">
          <AnnualRanking />
        </div>
      </div>
    </div>
  );
}
