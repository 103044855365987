import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Cookie from './Cookie';
import Header from './Header';
import Footer from './Footer';
import * as ROUTES from '../constants/routes';
import { useAuthentication, useScrollTop } from '../hooks';
import {
  fetchFields,
  fetchCelebrityIdsByCountry,
  fetchCelebrityIdsByField,
} from '../actions';

import Home from './Home';
import Vote from './Vote';
import Account from './Account';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignOut from './SignOut';
import PasswordForget from './PasswordForget';
import CelebritySuggest from './CelebritySuggest';
import CelebrityProfile from './Celebrity/CelebrityProfile';
import CelebrityCompare from './Celebrity/CelebrityCompare';
import Admin from './Admin';
import Ratings from './Ratings';
import Info from './Info';

function ScrollTop() {
  useScrollTop();
  return null;
}

export default function App() {
  const loading = useAuthentication();

  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const promises = [];
    promises.push(dispatch(fetchFields()));
    promises.push(dispatch(fetchCelebrityIdsByCountry()));
    promises.push(dispatch(fetchCelebrityIdsByField()));
    Promise.all(promises).then(() => {
      setLoadingData(false);
    });
  }, [dispatch]);

  if (loading || loadingData) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center mt-5">
            <div
              className="spinner-grow spinner-grow-lg text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <ScrollTop />
      <Cookie />
      <Header />
      <main role="main" style={{ minHeight: '350px' }}>
        <Route path={ROUTES.HOME} exact component={Home} />
        <Route path={ROUTES.VOTE} component={Vote} />
        <Route path={ROUTES.RATINGS} exact component={Ratings} />
        <Route path={ROUTES.ACCOUNT} component={Account} />
        <Route path={ROUTES.SIGNUP} component={SignUp} />
        <Route path={ROUTES.SIGNIN} component={SignIn} />
        <Route path={ROUTES.SIGNOUT} component={SignOut} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget} />
        <Route
          path={`${ROUTES.CELEBRITY_SUGGEST}/:id?`}
          component={CelebritySuggest}
        />
        <Route
          path={`${ROUTES.CELEBRITY_PROFILE}/:id/:fullname`}
          component={CelebrityProfile}
        />
        <Route
          path={`${ROUTES.CELEBRITY_COMPARE}/:id1?/:id2?`}
          component={CelebrityCompare}
        />
        <Route path={ROUTES.ADMIN} component={Admin} />
        <Route
          path={[
            ROUTES.ABOUT,
            ROUTES.CONTACT,
            ROUTES.TOS,
            ROUTES.PP,
            ROUTES.CU,
          ]}
          component={Info}
        />
      </main>
      <Footer />
    </Router>
  );
}
