import React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';

import { useAdminAuthorization } from '../../hooks';
import * as ROUTES from '../../constants/routes';
import AdminHome from './AdminHome';
import CelebrityList from './CelebrityList';
import CelebrityItem from './CelebrityItem';
import FieldList from './FieldList';

export default function Admin() {
  useAdminAuthorization();

  return (
    <>
      <div className="nav-scroller bg-white shadow-sm">
        <div className="container">
          <nav className="nav nav-underline">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={ROUTES.ADMIN}
              exact
            >
              Admin Home
            </NavLink>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={ROUTES.ADMIN_CELEBRITY_LIST}
            >
              Celebrities
            </NavLink>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={ROUTES.ADMIN_FIELD_LIST}
            >
              Fields
            </NavLink>
          </nav>
        </div>
      </div>
      <Switch>
        <Route exact path={ROUTES.ADMIN} component={AdminHome} />
        <Route
          exact
          path={`${ROUTES.ADMIN_CELEBRITY_LIST}/:filter?`}
          component={CelebrityList}
        />
        <Route
          exact
          path={`${ROUTES.ADMIN_CELEBRITY_DETAIL}/:id`}
          component={CelebrityItem}
        />
        <Route
          exact
          path={`${ROUTES.ADMIN_FIELD_LIST}`}
          component={FieldList}
        />
      </Switch>
    </>
  );
}
