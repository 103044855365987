import React from 'react';
import { useToasts } from 'react-toast-notifications';

import { useSignInAuthorization, useFirebase } from '../hooks';

export default function withEmailVerification(Component) {
  return function(props) {
    // TODO: temproraly force to ignore email verification
    // const verified = useVerifiedEmailAuthorization();
    useSignInAuthorization();
    const verified = true;
    const firebase = useFirebase();
    const { addToast } = useToasts();

    async function handleClick() {
      try {
        await firebase.sendEmailVerification();
        addToast('Confirmation e-mail has been sent.', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (error) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }

    return !verified ? (
      <div className="container">
        <div className="alert alert-primary mt-3">
          <span className="font-weight-bold">Verify your email address:</span>{' '}
          Check your emails (spam folder included) for a confirmation email.{' '}
          <br />
          Note: Reload this page after verifying your email.
        </div>
        <button className="btn btn-primary" type="button" onClick={handleClick}>
          Send a new confirmation email
        </button>
      </div>
    ) : (
      <Component {...props} />
    );
  };
}
