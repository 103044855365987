import React, { useMemo, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import $ from 'jquery';

import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/celebrityStatus';
import * as REASONS from '../../constants/rejectionReasons';
import {
  useCelebrityQuery,
  useSignInAuthorization,
  useFirebase,
} from '../../hooks';
import { getAuthUser } from '../../reducers';
import { deleteCelebrity } from '../../actions';
import { celebrityStatusText, celebrityProfileUrl } from '../../helpers';
import Icon from '../Icon';
import DeleteDialog from '../DeleteDialog';

export default function MySuggestions() {
  useSignInAuthorization();
  const authUser = useSelector(getAuthUser);
  const query = useMemo(
    () => ({
      where: ['createdBy', '==', authUser.uid],
      key: 'mySuggestions',
    }),
    [authUser]
  );
  const { celebrities, isFetching, error } = useCelebrityQuery(query);

  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  async function handleDelete(celebrity) {
    try {
      // delete photo if exists
      const photo = celebrity.photo;
      if (typeof photo === 'string') {
        const storage = firebase.storage.ref();
        const file = storage.child(photo);
        await file.delete().catch(error => error);
      }

      await dispatch(deleteCelebrity(celebrity.id));

      addToast('The celebrity was deleted.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCelebrity, setSelectedCelebrity] = useState(null);

  function openDeleteDialog(celebrity) {
    setSelectedCelebrity(celebrity);
    setIsOpen(true);
  }

  function toggleDeleteDialog() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="container">
      <div className="row my-3">
        {isFetching ? (
          <div className="col-12 text-center">
            <div
              className="spinner-grow spinner-grow-lg text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          error && (
            <div className="col-12 text-center">
              <span className="text-danger">{error.message}</span>
            </div>
          )
        )}
        {!isFetching && !celebrities.length && (
          <div
            className="col-12 text-center d-flex align-items-center justify-content-center"
            style={{ minHeight: '350px' }}
          >
            <span className="text-primary">No suggested celebrity.</span>
          </div>
        )}
        {!!celebrities.length && (
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <Celebrities
                    celebrities={celebrities}
                    onDeleteClick={openDeleteDialog}
                  />
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <DeleteDialog
        isOpen={isOpen}
        onDelete={() => handleDelete(selectedCelebrity)}
        onToggle={toggleDeleteDialog}
        itemName={
          selectedCelebrity &&
          `${selectedCelebrity.firstname} ${selectedCelebrity.lastname}`
        }
      />
    </div>
  );
}

function Celebrities({ celebrities, onDeleteClick }) {
  useLayoutEffect(() => {
    $(function() {
      $('[data-toggle="popover"]').popover();
    });
  });

  return celebrities.map((celebrity, idx) => (
    <tr key={celebrity.id}>
      <th className="align-middle" scope="row">
        {idx + 1}
      </th>
      <td className="align-middle">
        {celebrity.status === STATUS.APPROVED ? (
          <Link to={celebrityProfileUrl(celebrity.id, celebrity)}>
            {celebrity.firstname} {celebrity.lastname}
          </Link>
        ) : (
          <span>
            {celebrity.firstname} {celebrity.lastname}
          </span>
        )}
      </td>
      <td className="align-middle">
        {celebrityStatusText(celebrity.status)}{' '}
        {celebrity.status === STATUS.REJECTED && (
          <a
            role="button"
            data-toggle="popover"
            data-trigger="focus"
            title="Rejection Reason"
            data-content={REASONS[celebrity.rejectionReason]}
            href="#reason"
          >
            <Icon icon={['far', 'question-circle']} className="text-info" />
          </a>
        )}
      </td>
      <td className="align-middle">
        {celebrity.status !== STATUS.APPROVED && (
          <>
            <Link
              className="btn mr-2"
              to={`${ROUTES.CELEBRITY_SUGGEST}/${celebrity.id}`}
            >
              <Icon icon="edit" fixedWidth className="text-dark" size="lg" />
            </Link>
            <button className="btn" onClick={() => onDeleteClick(celebrity)}>
              <Icon
                icon="trash-alt"
                fixedWidth
                className="text-danger"
                size="lg"
              />
            </button>
          </>
        )}
      </td>
    </tr>
  ));
}
