import React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import Settings from './Settings';
import Favorites from './Favorites';
import PasswordChange from './PasswordChange';
import MySuggestions from './MySuggestions';
import AccountDelete from './AccountDelete';
import { useSignInAuthorization } from '../../hooks';

function Account() {
  useSignInAuthorization();

  return (
    <>
      <div className="nav-scroller bg-white shadow-sm">
        <div className="container">
          <nav className="nav nav-underline">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={ROUTES.ACCOUNT_SETTINGS}
            >
              Settings
            </NavLink>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={ROUTES.ACCOUNT_FAVORITES}
            >
              Favorites
            </NavLink>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={ROUTES.ACCOUNT_MY_SUGGESTIONS}
            >
              My Suggestions
            </NavLink>
          </nav>
        </div>
      </div>
      <Switch>
        <Route exact path={ROUTES.ACCOUNT_SETTINGS} component={Settings} />
        <Route exact path={ROUTES.ACCOUNT_FAVORITES} component={Favorites} />
        <Route
          exact
          path={ROUTES.ACCOUNT_PASSWORD_CHANGE}
          component={PasswordChange}
        />
        <Route
          exact
          path={ROUTES.ACCOUNT_MY_SUGGESTIONS}
          component={MySuggestions}
        />
        <Route exact path={ROUTES.ACCOUNT_DELETE} component={AccountDelete} />
      </Switch>
    </>
  );
}

export default Account;
