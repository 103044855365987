import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <h2>Thank you for using Reputelo!</h2>
      <p>
        Our mission is to highlighted celebrities who inspire the world with own
        characteristic by people around the world.
      </p>
      <p>
        We wrote this policy to help you understand what information we collect,
        how we use it and what choices you have about it. Because we’re an
        internet entrepreneurship, some of the concepts below are a little
        technical, but we’ve tried our best to explain things in a simple and
        clear way. We welcome your questions and comments on this policy.
      </p>
      <h2>We collect information in a few different ways</h2>
      <section>
        <h3>1. When you give it to us or give us permission to obtain it</h3>
        <p>
          When you sign up for or use Reputelo you voluntarily share certain
          information including your full name, email address, password,
          celebrity photos, and any other information you give us. You can also
          choose to share your precise location using your device settings. We
          will still use your IP address, which is used to approximate your
          location, even if you don't choose to share your precise location.
        </p>
        <p>
          If you connect your Facebook, Google or Twitter to Reputelo, we use
          information from those accounts (such as your friends or contacts) to
          improve your experience. This is dependent on the privacy policies or
          settings for those accounts.
        </p>
      </section>
      <section>
        <h3>2. We also get technical information when you use Reputelo</h3>
        <p>
          When you use a website, mobile application or other internet service,
          certain internet and electronic network activity information gets
          created and logged automatically. This is also true when you use
          Reputelo. Here are some of the types of information we collect:
        </p>

        <h4>- Log data:</h4>
        <p>
          When you use Reputelo, our servers record information (“log data”),
          including information that your browser automatically sends whenever
          you visit a website, or that your mobile app automatically sends when
          you’re using it. This log data includes your Internet Protocol address
          (which we use to infer your approximate location), the address of and
          activity on websites you visit that incorporate Reputelo features
          (like the “ Submit” button), searches, browser type and settings, the
          date and time of your request, how you used Reputelo, cookie data and
          device data. You can learn more about we collect some technical
          information when you use our service. These are below:
        </p>
        <ul>
          <li>Usage data and preferences</li>
          <li>Sign-up data</li>
          <li>Authentication detail</li>
          <li>Login history</li>
          <li>Settings</li>
          <li>Email preferences</li>
          <li>App and web notification preferences</li>
          <li>Privacy and personalization settings</li>
          <li>Session log</li>
          <li>Actions taken during session</li>
          <li>
            Device specific information (device type and identifiers; events,
            such as crashes)
          </li>
          <li>Account profile information</li>
          <li>Social contacts you authorize us to receive</li>
          <li>Reputelo content engagement</li>
          <li>Search queries</li>
          <li>Comments</li>
          <li>Ads engagement</li>
          <li>Ads served to a page and likelihood you have seen them</li>
          <li>Actions on ads (clicks on ads, saves on pages featuring ads)</li>
          <li>Cookies associated with your account</li>
          <li>Verifying your login credentials</li>
          <li>
            Applying your preferences and settings (notification, privacy, etc.)
          </li>
          <li>
            Remembering you across sessions and devices and keeping a record of
            notifications sent
          </li>
          <li>
            Pages viewed and actions taken on sites or with browsers
            incorporating Reputelo features
          </li>
          <li>Measuring popularity of Reputelo features</li>
        </ul>
        <h4>- Cookie data:</h4>
        <p>
          We also use “cookies” (small text files sent by your computer each
          time you visit Reputelo, unique to your Reputelo account or your
          browser) or similar technologies to get log data. When we use cookies
          or other similar technologies, we use session cookies (that last until
          you close your browser) or persistent cookies (that last until you or
          your browser delete them). For example, we use cookies to store your
          Full Name preferences or other settings so you don‘t have to set them
          up every time you visit Reputelo. Some of the cookies we use are
          associated with your Reputelo account (including information about
          you, such as the email address you gave us) and other cookies are not.
        </p>
        <h4>- Device information:</h4>
        <p>
          In addition to log data, we collect information about the device
          you’re using Reputelo on, including the type of device, operating
          system, settings, unique device identifiers and crash data that helps
          us understand when something breaks.
        </p>
        <h4>- Clickstream data and inferences:</h4>
        <p>
          When you’re on Reputelo, we use your activity along with information
          you provided when you first signed up and information from our
          partners and advertisers to make inferences about you and your
          preferences. We may also infer information about your education or
          professional experience based on your activity when you link your
          account to accounts you have with third parties like Facebook, Twitter
          or Google.
        </p>
      </section>
      <section>
        <h3>3. Our partners and advertisers share information with us</h3>
        <p>
          We also get information about you and your activity outside Reputelo
          from our affiliates, advertisers, partners and other third parties we
          work with. For example:
        </p>
        <ul>
          <li>
            Some websites or apps use Reputelo features such as our “Submit”
            button, or you may install our “Submit” button for your browser. If
            so, we collect log data from those sites or apps.
          </li>
          <li>
            Online advertisers or third parties share information with us to
            measure, report on or improve the performance of ads on Reputelo, or
            to figure out what kinds of ads to show you on or off of Reputelo.
            This includes information about your visits to an advertiser's site
            or purchases you made from them, or information about your interests
            from a third-party service, which we might use to help show you ads.
          </li>
        </ul>

        <h4>What we do with the info we collect</h4>
        <ul>
          <li>
            Work with law enforcement and keep Reputelo safe. We may get
            requests for account information from law enforcement authorities
            like the police or courts. Review that what you do on Reputelo to
            detect activity that poses a risk to the safety of you, our
            community and/or members of the public.
          </li>
          <li>
            Conduct analytics and research on who is using Reputelo and what
            they are doing.
          </li>
          <li>Improve Reputelo and offer new features.</li>
        </ul>
        <p>
          We have a legitimate interest in delivering ads that are relevant,
          interesting and personal to you in order to generate revenue. To
          further these interests we use the information we collect to:
        </p>
        <ul>
          <li>
            We customize the ad content we show you by identifying your
            interests based on your onsite and offsite activities, as well as by
            using information we receive from ad partners or other third
            parties. Where we use cookies to identify your offsite interests,
            we’ll get your consent where we need to. Where ad partners or other
            third parties share information with us about you, we rely on the
            consent they already obtained.
          </li>
          <li>
            Tell our ad partners how their Reputelo ads are doing, and how to
            make them better. Some of this information is aggregated.
          </li>
          <li>
            We have a legitimate interest in using information we collect to
            customize your Reputelo experience based on your offsite behavior.
            When we identify your interests based on your offsite behavior with
            cookies, we will obtain any consent that we may need.
          </li>
        </ul>
        <p>
          In addition to the specific circumstances above, we’ll only use your
          information with your consent in order to:
        </p>
        <ul>
          <li>
            Send you marketing materials by email, text, push notification or
            phone call depending on your account or operating system settings.
            Each time we send you marketing materials, we give you the option to
            unsubscribe.
          </li>
          <li>
            Identify your precise location and customize the content we show
            you. You can opt in to this in your device operating system
            settings.
          </li>
          <li>
            Tell our ad partners how their ads are doing using the Reputelo tag.
            The Reputelo tag (a piece of code an advertiser puts on their site)
            delivers insights to us and our ad partners about actions that a
            person takes on their website after viewing an ad on Reputelo.
          </li>
        </ul>
        <p>We’ll also rely on your consent where we use cookies to:</p>
        <ul>
          <li>
            Identify you across different browser sessions. This means if you
            log into Reputelo from your phone (or any device), we'll remember
            that you're you and then you won't need to enter your login details
            each time you visit Reputelo.
          </li>
          <li>
            Show you ads you might be interested in. We use cookies to identify
            your interests based on your offsite activity. We do this for people
            currently using Reputelo and for new folks.
          </li>
        </ul>
      </section>
      <h2>Transferring your information</h2>
      <p>
        Reputelo is a worldwide service. By using our products or services, you
        authorize us to transfer and store your information outside your home
        country, including in the United States, for the purposes described in
        this policy. The privacy protections and the rights of authorities to
        access your personal information in such countries may not be equivalent
        to those of your home country.
      </p>
      <h2>Choices you have about your info</h2>
      <p>
        Our goal is to give you simple and meaningful choices regarding your
        information. If you have a Reputelo account, many of these controls are
        built directly into Reputelo or your settings. For example, you can:
      </p>
      <ul>
        <li>Edit information in your profile at any time.</li>
        <li>
          Link or unlink your Reputelo account from other services (like
          Facebook, Google or Twitter). For some services (like Facebook), you
          can also decide whether or not to publish your Reputelo activity to
          that service.
        </li>
        <li>
          Close your account at any time. When you close your account, we’ll
          delete your account and your all information. But we don’t remove the
          approved celebrities you suggested.
        </li>
      </ul>
      <p>
        We also support settings such as Limit Ad Tracking in iOS, Ads
        Personalization in Android devices as well as Do Not Track in browsers.
      </p>
      <p>
        You also have choices available to you through the device or software
        you use to access Reputelo. For example:
      </p>
      <ul>
        <li>
          The browser you use lets you control cookies or other types of local
          data storage.
        </li>
        <li>
          Your mobile device lets you choose how and whether your precise
          location, photos, contacts and other data is shared with us.
        </li>
      </ul>
      <p>
        To learn more about these choices, please see the information provided
        by your device or software provider.
      </p>
      <h2>How and when we share information</h2>
      <p>
        If your suggested celebrities have been approved by our admins, they can
        be seen by everyone at the vote. We also make this public information
        available through what are called APIs (basically a technical way to
        share information quickly). We also share your information with:
      </p>
      <ul>
        <li>
          Other services, to enable you to sign up for or log in to Reputelo, or
          when you decide to link your Reputelo account to those services, like
          Facebook, Twitter or Google, or when you publish your content on
          Reputelo to them.
        </li>
        <li>
          Services we use to market Reputelo to you on sites and apps other than
          Reputelo, such as Facebook Ads, Google Marketing Platform and others
          from time to time.
        </li>
        <li>
          Online advertisers and third-party companies that we or they use to
          audit or improve the delivery and performance of ads or content on
          websites and apps (for example, through Google Analytics). This
          includes us sharing the information about your activity on Reputelo.
        </li>
        <li>
          Third-party companies, service providers or individuals that we employ
          to process information on our behalf based on our instructions and for
          the purposes described in this Privacy Policy. Some information we
          acquire may be collected by third-party providers on our behalf.
        </li>
        <li>
          Law enforcement agencies or government agencies. We only share
          information if we believe that disclosure is reasonably necessary to
          comply with a law, regulation or legal request; to protect the safety,
          rights, or property of the public, any person, or Reputelo; or to
          detect, prevent, or otherwise address fraud, security or technical
          issues.
        </li>
        <li>
          Our wholly-owned subsidiaries and affiliates. If we were to engage in
          a merger, acquisition, bankruptcy, dissolution, reorganization, or
          similar transaction or proceeding that involves the transfer of the
          information described in this Policy, we would share your information
          with a party involved in such a process (for example, a potential
          purchaser).
        </li>
      </ul>
      <h2>How long we keep your information</h2>
      <p>
        We keep your information only so long as we need it to provide Reputelo
        to you and fulfill the purposes described in this policy. This is also
        the case for anyone that we share your information with and who carries
        out services on our behalf. When we no longer need to use your
        information and there is no need for us to keep it to comply with our
        legal or regulatory obligations, we’ll either remove it from our systems
        or depersonalize it so that we can't identify you.
      </p>
      <h3>Your options</h3>
      <p>
        You have options in relation to the information that we have about you.
        To exercise these options, please contact us. Your options are:
      </p>
      <ul>
        <li>
          Request access to the information we hold about you. We'll usually
          share this with you within 30 days of you asking us for it.
        </li>
        <li>
          Have your information corrected. You can update your information in
          your profile. If you have problems updating the information contact
          us.
        </li>
        <li>
          Have the information you provided to us sent to another organization,
          where we hold this information with your consent or for the
          performance of a contract with you, and, where it's technically
          feasible for us to do so.
        </li>
        <li>
          Request more details about the information we collect and how and why
          we use and share it.
        </li>
      </ul>
      <p>
        We will not discriminate against you if you choose to exercise your
        options related to your personal information. We work to be clear with
        you about your options and the consequences of exercising certain
        options. If you have questions, please contact us.
      </p>
      <h3>How we make changes to this policy</h3>
      <p>
        We may change this policy from time to time and if we do, we’ll post any
        changes on this page. If you continue to use Reputelo after those
        changes are in effect, you agree to the new policy. If the changes are
        significant, we may provide a more prominent notice or get your consent,
        as required by law.
      </p>

      <p>
        Effective date: November 1, 2019
        <br />
        Last updated: October 2, 2019
      </p>
    </div>
  );
}
