import React from 'react';
import { Link, NavLink, Switch, Route } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import About from './About';
import ContactUs from './ContactUs';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import CookiesUse from './CookiesUse';

const pages = [
  {
    route: ROUTES.ABOUT,
    title: 'About',
    component: About,
  },
  {
    route: ROUTES.CONTACT,
    title: 'Contact Us',
    component: ContactUs,
  },
  {
    route: ROUTES.TOS,
    title: 'Terms of Service',
    component: TermsOfService,
  },
  {
    route: ROUTES.PP,
    title: 'Privacy Policy',
    component: PrivacyPolicy,
  },
  {
    route: ROUTES.CU,
    title: 'Cookies Use',
    component: CookiesUse,
  },
];

export default function Info() {
  return (
    <>
      <div className="nav-scroller bg-white shadow-sm mb-2">
        <div className="container">
          <nav className="nav nav-underline">
            {pages.map(page => (
              <NavLink
                key={page.route}
                className="nav-link"
                activeClassName="active"
                to={page.route}
              >
                {page.title}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <Switch>
        {pages.map(page => (
          <Route
            key={page.route}
            exact
            path={page.route}
            component={page.component}
          />
        ))}
      </Switch>
    </>
  );
}

export function PolicyText() {
  return (
    <p className="text-center">
      By continuing, you agree to our{' '}
      <Link to={ROUTES.TOS}>Terms of Service</Link>,{' '}
      <Link to={ROUTES.PP}>Privacy Policy</Link> and{' '}
      <Link to={ROUTES.CU}>Cookies</Link>.
    </p>
  );
}
