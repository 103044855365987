import { useLayoutEffect, useState } from 'react';

export default function useHtmlElementSize(elem) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      let width, height;
      if (elem) {
        const computedStyle = window.getComputedStyle(elem);
        const paddingLeft = parseFloat(computedStyle.paddingLeft);
        const paddingRight = parseFloat(computedStyle.paddingRight);
        const paddingTop = parseFloat(computedStyle.paddingTop);
        const paddingBottom = parseFloat(computedStyle.paddingBottom);
        width = elem.clientWidth - paddingLeft - paddingRight;
        height = elem.clientHeight - paddingTop - paddingBottom;
        setSize([width, height]);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [elem]);
  return size;
}
