import React from 'react';
import { Provider } from 'react-redux';
import {
  ToastProvider,
  DefaultToastContainer,
  DefaultToast,
} from 'react-toast-notifications';

import { FirebaseContext } from '../firebase';
import App from './App';

function ToastContainer(props) {
  return <DefaultToastContainer {...props} className="my-toast-container" />;
}

function Toast({ children, ...props }) {
  return (
    <DefaultToast {...props} className="my-toast">
      {children}
    </DefaultToast>
  );
}

export default function Root({ store, api }) {
  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={api}>
        <ToastProvider components={{ ToastContainer, Toast }}>
          <App />
        </ToastProvider>
      </FirebaseContext.Provider>
    </Provider>
  );
}
