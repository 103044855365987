import * as ROUTES from './constants/routes';
import * as STATUS from './constants/celebrityStatus';

export function celebrityProfileUrl(id, celebrity) {
  return `${ROUTES.CELEBRITY_PROFILE}/${id}/${celebrity.firstname}-${celebrity.lastname}`;
}

export function celebrityCompareUrl(id1, id2) {
  return `${ROUTES.CELEBRITY_COMPARE}/${id1}/${id2}`;
}

export function celebrityStatusText(status) {
  switch (status) {
    case STATUS.PENDING:
      return 'Pending';
    case STATUS.APPROVED:
      return 'Approved';
    case STATUS.REJECTED:
      return 'Rejected';
    default:
      return 'Unknown status';
  }
}

export function getRatingLabel(field, natl = false) {
  return `${field}Rating${natl ? 'Natl' : ''}`;
}

export function getRatingHistoryLabel(field, natl = false) {
  return `${field}RatingHistory${natl ? 'Natl' : ''}`;
}

export function getRankingHistoryLabel(field, natl = false) {
  return `${field}RankingHistory${natl ? 'Natl' : ''}`;
}

export function getScoreLabel(field, year, natl = false) {
  return `${field}Score${year}${natl ? 'Natl' : ''}`;
}

export function getVoteCountLabel(field, natl = false) {
  return `${field}VoteCount${natl ? 'Natl' : ''}`;
}

export function getIsPublishableLabel(field, natl = false) {
  return `${field}IsPublishable${natl ? 'Natl' : ''}`;
}

export function getWinCountLabel(field, natl = false) {
  return `${field}WinCount${natl ? 'Natl' : ''}`;
}

export function getDefeatCountLabel(field, natl = false) {
  return `${field}DefeatCount${natl ? 'Natl' : ''}`;
}

export function isVowel(c) {
  return ['a', 'e', 'i', 'o', 'u'].indexOf(c.toLowerCase()) !== -1;
}
