import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useFirebase } from '.';
import { setAuthUser, setProfile } from '../actions';

export default function useAuthentication() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const firebase = useFirebase();
  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChanged((authUser, profile) => {
      if (authUser) {
        authUser.reload();
        authUser.getIdToken(true);
      }
      dispatch(setAuthUser(authUser));
      dispatch(setProfile(profile));
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch, firebase]);
  return loading;
}
