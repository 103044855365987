import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useSearch } from '../hooks';
import CelebrityImage from './Celebrity/CelebrityImage';
import { getFields } from '../reducers';
import * as ROUTES from '../constants/routes';

export default function Search({ onClick, createUrl, ...props }) {
  const [focused, setFocused] = useState(false);
  const { query, setQuery, hits, loading, error } = useSearch('');

  function handleValueChange(event) {
    event.preventDefault();
    setQuery(event.target.value);
  }

  function handleClick(event, celebrity) {
    event.preventDefault();
    setQuery('');
    onClick(celebrity.objectID, celebrity);
  }

  let timeoutId;
  function handleInputFocus(state) {
    timeoutId = setTimeout(() => {
      setFocused(state);
    }, 300);
  }

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const fields = useSelector(getFields);

  return (
    <>
      <form
        className="form-inline my-2 my-lg-0"
        autoComplete="off"
        spellCheck="off"
        onSubmit={e => e.preventDefault()}
      >
        {props.label && props.id && (
          <label className="mr-2" htmlFor={props.id}>
            {props.label}
          </label>
        )}
        <div>
          <input
            className="form-control mr-sm-2"
            placeholder="Search"
            aria-label="Search"
            type="text"
            name="query"
            id={props.id ? props.id : 'query'}
            value={query}
            autoComplete="off"
            onChange={handleValueChange}
            onFocus={() => handleInputFocus(true)}
            onBlur={() => handleInputFocus(false)}
            style={{ width: '250px' }}
          />
          <div
            className={`list-group position-absolute pt-1 search-container${
              focused ? ' d-flex' : ' d-none'
            }`}
          >
            {loading ? (
              <div className="list-group-item d-flex justify-content-center">
                <div
                  className="spinner-grow spinner-grow-sm text-primary"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              error && (
                <div className="list-group-item">
                  <span className="text-danger">{error.message}</span>
                </div>
              )
            )}
            {hits && hits.length === 0 ? (
              <div className="list-group-item">
                No result
                <br />
                <Link to={ROUTES.CELEBRITY_SUGGEST}>Suggest the celebrity</Link>
              </div>
            ) : (
              hits && (
                <>
                  <div className="search-results-container overflow-auto">
                    {hits.map((celebrity, idx) => (
                      <SearchResultItem
                        key={celebrity.objectID}
                        celebrity={celebrity}
                        url={
                          createUrl && createUrl(celebrity.objectID, celebrity)
                        }
                        onClick={e => onClick && handleClick(e, celebrity)}
                        fields={fields}
                        last={idx === hits.length - 1}
                      />
                    ))}
                  </div>
                  <div className="list-group-item p-1 pl-3 text-dark">
                    <small>
                      Search by{' '}
                      <a
                        className="text-dark"
                        href="https://www.algolia.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Algolia
                      </a>
                    </small>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </form>
    </>
  );
}

function SearchResultItem({ celebrity, url, onClick, fields, last }) {
  const imageContainerRef = useRef(null);

  return (
    <div
      className={`list-group-item position-relative${
        last ? ' border-bottom-0 rounded-0' : ''
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-3 px-0" ref={imageContainerRef}>
            <CelebrityImage
              celebrity={celebrity}
              container={imageContainerRef.current}
            />
          </div>
          <div className="col-9 pr-0 position-static">
            <div>
              <a
                className="stretched-link"
                href={url}
                onClick={event => onClick && onClick(event, celebrity)}
              >
                {celebrity.firstname} {celebrity.lastname}
              </a>
            </div>
            {celebrity.fields.length > 0 && (
              <div style={{ fontSize: '0.5em' }}>
                {celebrity.fields.reduce(
                  (acc, field, idx) =>
                    acc +
                    fields[field] +
                    (idx !== celebrity.fields.length - 1 ? ', ' : ''),
                  ''
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
