import { combineReducers } from 'redux';

import users from './users';
import celebrities, * as fromCelebrities from './celebrities';
import auth, * as fromAuth from './auth';
import config, * as fromConfig from './config';

export default combineReducers({ users, celebrities, auth, config });

export const getCelebrityQueryResult = (state, key = undefined) =>
  fromCelebrities.getCelebrityQueryResult(state.celebrities, key);
export const getCelebritiesByKeyword = (state, keyword, key = undefined) =>
  fromCelebrities.getCelebritiesByKeyword(state.celebrities, keyword, key);
export const getCelebrity = (state, id) =>
  fromCelebrities.getCelebrity(state.celebrities, id);
export const getCelebrityIds = (state, key = undefined) =>
  fromCelebrities.getCelebrityIds(state.celebrities, key);
export const getLastCelebrity = (state, key = undefined) =>
  fromCelebrities.getLastCelebrity(state.celebrities, key);

export const getAuthUser = state => fromAuth.getAuthUser(state.auth);
export const getSignInError = state => fromAuth.getSignInError(state.auth);
export const getSignInFetching = state =>
  fromAuth.getSignInFetching(state.auth);
export const getSignUpError = state => fromAuth.getSignUpError(state.auth);
export const getSignUpFetching = state =>
  fromAuth.getSignUpFetching(state.auth);
export const getProfile = state => fromAuth.getProfile(state.auth);
export const getProfileIsFetching = state =>
  fromAuth.getProfileIsFetching(state.auth);
export const getFavorites = state => fromAuth.getFavorites(state.auth);
export const getVotingCelebrityIds = state =>
  fromAuth.getVotingCelebrityIds(state.auth);

export const getCurrentField = state =>
  fromConfig.getCurrentField(state.config);
export const getFields = state => fromConfig.getFields(state.config);
export const getCurrentCountry = state =>
  fromConfig.getCurrentCountry(state.config);
export const getVoteableCountries = state =>
  fromConfig.getVoteableCountries(state.config);
export const getVoteableFields = state =>
  fromConfig.getVoteableFields(state.config);
export const getCountriesForRatings = state =>
  fromConfig.getCountriesForRatings(state.config);
export const getFieldsForRatings = state =>
  fromConfig.getFieldsForRatings(state.config);
