import React from 'react';
import { useSelector } from 'react-redux';

import { useCelebrityByRating } from '../../hooks';

import { getCurrentField, getCurrentCountry } from '../../reducers';
import CelebrityCard from './CelebrityCard';

export default function CurrentRating() {
  const field = useSelector(getCurrentField);
  const country = useSelector(getCurrentCountry);
  const {
    celebrities,
    isFetching,
    error,
    more,
    endOfResult,
    orderBy,
  } = useCelebrityByRating(field, country);

  function renderCelebrities(celebrities) {
    return celebrities.map((celebrity, idx) => (
      <CelebrityCard
        key={celebrity.id}
        index={idx}
        celebrity={celebrity}
        text={`Rating: ${Math.round(celebrity[orderBy]) || '-'}`}
      />
    ));
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <h3 className="text-primary">Current Ratings</h3>
        </div>
      </div>
      {!isFetching && !celebrities.length ? (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <span className="text-primary">
              Suggest more and Vote more to see someone here.
            </span>
          </div>
        </div>
      ) : (
        renderCelebrities(celebrities)
      )}
      {!isFetching && error && (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <span className="text-danger">{error.message}</span>
          </div>
        </div>
      )}
      {!!celebrities.length && endOfResult && (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <span className="text-primary">No more celebrity.</span>
          </div>
        </div>
      )}
      {!endOfResult && (
        <div className="row">
          <div className="col-12 mb-2 text-center">
            <button
              className="btn btn-secondary text-primary"
              type="button"
              onClick={more}
            >
              {isFetching ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm text-primary"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                'More'
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
