import { combineReducers } from 'redux';

const DEFAULT_KEY = 'default';

const data = doc => ({ ...doc.data(), id: doc.id });

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CELEBRITIES_SUCCESS': {
      const nextState = { ...state };
      action.docs.forEach(doc => {
        if (doc.exists) {
          nextState[doc.id] = data(doc);
        }
      });
      return nextState;
    }
    case 'FETCH_CELEBRITY_SUCCESS':
      return action.doc.exists
        ? { ...state, [action.doc.id]: data(action.doc) }
        : state;
    case 'DELETE_CELEBRITY_SUCCESS': {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CELEBRITIES_SUCCESS':
      let nextState = [];
      if (action.query.startAt || action.query.startAfter) {
        nextState = [...state];
      }
      action.docs.forEach(doc => {
        if (doc.exists) {
          nextState.push(doc.id);
        }
      });
      return nextState;
    case 'DELETE_CELEBRITY_SUCCESS':
      return state.filter(id => id !== action.id);
    default:
      return state;
  }
};

const queries = (state = {}, action) => {
  const key = (action.query && action.query.key) || DEFAULT_KEY;
  switch (action.type) {
    case 'FETCH_CELEBRITIES_REQUEST':
      return {
        ...state,
        [key]: {
          ...state[key],
          query: action.query,
          isFetching: true,
          error: null,
        },
      };
    case 'FETCH_CELEBRITIES_SUCCESS':
      const query = state[key] || {};
      const nextQuery = {
        ...query,
        ids: ids(state[key] && state[key].ids, action),
        isFetching: false,
        error: null,
      };
      if (action.docs.length) {
        nextQuery.lastCelebrity = action.docs[action.docs.length - 1];
        nextQuery.endOfResult = false;
      } else {
        nextQuery.endOfResult = true;
      }
      return {
        ...state,
        [key]: nextQuery,
      };
    case 'FETCH_CELEBRITIES_FAILURE':
      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: false,
          error: action.error,
        },
      };
    case 'DELETE_CELEBRITY_SUCCESS':
      const nextState = {};
      Object.keys(state).forEach(key => {
        nextState[key] = {
          ...state[key],
          ids: ids(state[key] && state[key].ids, action),
        };
      });
      return nextState;
    default:
      return state;
  }
};

export default combineReducers({ byId, queries });

const getCelebrities = (state, key) =>
  state.queries[key] && state.queries[key].ids
    ? state.queries[key].ids.map(id => state.byId[id])
    : [];

const getIsFetching = (state, key) =>
  !state.queries[key] || state.queries[key].isFetching;

const getError = (state, key) =>
  state.queries[key] ? state.queries[key].error : null;

const getEndOfResult = (state, key) =>
  state.queries[key] && state.queries[key].endOfResult;

export const getCelebrityQueryResult = (state, key = DEFAULT_KEY) => ({
  celebrities: getCelebrities(state, key),
  isFetching: getIsFetching(state, key),
  error: getError(state, key),
  endOfResult: getEndOfResult(state, key),
});

export const getCelebritiesByKeyword = (state, keyword, key = DEFAULT_KEY) => {
  const celebrities = getCelebrities(state, key);
  const regex = new RegExp(keyword, 'gi');
  return celebrities.filter(
    c => c.firstname.search(regex) > -1 || c.lastname.search(regex) > -1,
  );
};

export const getCelebrity = (state, id) => state.byId[id];

export const getCelebrityIds = (state, key = DEFAULT_KEY) =>
  state.queries[key] ? state.queries[key].ids : [];

export const getLastCelebrity = (state, key = DEFAULT_KEY) =>
  state.queries[key] ? state.queries[key].lastCelebrity : null;
