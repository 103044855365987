import {
  faGoogle,
  faTwitter,
  faFacebook,
  faYoutube,
  faInstagram,
  faImdb,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faHeart,
  faEdit,
  faTimesCircle,
  faHeartBroken,
  faTrashAlt,
  faInfoCircle,
  faTimes,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendar,
  faHeart as farHeart,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appID: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const photosPath = 'photos';

export const algoliaConfig = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  searchKey: process.env.REACT_APP_ALGOLIA_SEARCH_KEY,
};

export const fontAwesomeIcons = [
  faGoogle,
  faFacebook,
  faTwitter,
  faYoutube,
  faBars,
  faCalendar,
  faHeart,
  farHeart,
  faEdit,
  faTimesCircle,
  faQuestionCircle,
  faHeartBroken,
  faTrashAlt,
  faInfoCircle,
  faTimes,
  faInstagram,
  faImdb,
  faExternalLinkAlt,
];
