import { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch';

import { firebaseConfig, algoliaConfig } from '../config';
import { useFirebase } from './';
import * as STATUS from '../constants/celebrityStatus';

const ALGOLIA_APP_ID = algoliaConfig.appId;
const ALGOLIA_SEARCH_KEY = algoliaConfig.searchKey;

const ALGOLIA_INDEX_NAME = 'celebrities';

export default function useSearch(initialQuery) {
  const [query, setQuery] = useState(initialQuery);
  const [hits, setHits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const firebase = useFirebase();

  useEffect(() => {
    let canceled = false;

    async function search(query) {
      let client;
      let index;
      let filters;
      const auth = firebase.auth;
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        const searchKeyUrl =
          'https://us-central1-' +
          firebaseConfig.projectId +
          '.cloudfunctions.net/getSearchKey/';
        const response = await fetch(searchKeyUrl, {
          headers: { Authorization: 'Bearer ' + token },
        });
        const data = await response.json();
        client = algoliasearch(algoliaConfig.appId, data.key);
      } else {
        filters = `status=${STATUS.APPROVED}`;
        client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);
      }
      const params = { query };
      if (filters) {
        params.filters = filters;
      }

      index = client.initIndex(ALGOLIA_INDEX_NAME);
      const result = await index.search(params);
      return result.hits;
    }

    let id;
    if (query.length > 2) {
      id = setTimeout(() => {
        setLoading(true);
        search(query)
          .then(hits => {
            if (!canceled) {
              setHits(hits);
              setError(null);
              setLoading(false);
            }
          })
          .catch(error => {
            if (!canceled) {
              setHits([]);
              setError(error);
              setLoading(false);
            }
          });
      }, 300);
    } else {
      setHits(null);
    }

    return () => {
      canceled = true;
      clearTimeout(id);
    };
  }, [query, firebase]);

  return { query, setQuery, hits, loading, error };
}
