import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import isEqual from 'lodash/isEqual';

import { getFavorites } from '../reducers';
import * as actions from '../actions';
import { useRouter, useSignInAuthorization } from './';
import * as ROUTES from '../constants/routes';

export default function useFavorites() {
  const favorites = useSelector(getFavorites, isEqual);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const isSignedIn = useSignInAuthorization(false);
  const { history } = useRouter();

  const addFavorite = useCallback(
    async celebrity => {
      try {
        if (!isSignedIn) {
          throw new Error('You should first sign in.');
        }
        await dispatch(actions.addFavorite(celebrity.id));
        addToast('Added to your favorites.', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (error) {
        if (error instanceof Error) {
          history.push(
            `${ROUTES.SIGNIN}?redirect=${ROUTES.CELEBRITY_PROFILE}/${celebrity.id}/${celebrity.firstname}-${celebrity.lastname}`
          );
        }
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [addToast, dispatch, history, isSignedIn]
  );

  const removeFavorite = useCallback(
    async celebrity => {
      try {
        await dispatch(actions.removeFavorite(celebrity.id));
        addToast('Removed from your favorites.', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (error) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [addToast, dispatch]
  );

  return { favorites, addFavorite, removeFavorite };
}
