import React from 'react';
import ProfileUpdate from './ProfileUpdate';
import { PasswordChangeButton } from './PasswordChange';
import { AccountDeleteButton } from './AccountDelete';

export default function Settings() {
  return (
    <>
      <ProfileUpdate />
      <div className="text-center">
        <PasswordChangeButton />
        <AccountDeleteButton />
      </div>
    </>
  );
}
