import React from 'react';
import ReactDOM from 'react-dom';

// bootstrap
import 'jquery/dist/jquery.slim';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import * as serviceWorker from './serviceWorker';

import './index.scss';

import Root from './components/Root';
import FirebaseAPI from './firebase';
import configureStore from './configureStore';
import configureFontAwesome from './configureFontAwesome';

configureFontAwesome();

const api = new FirebaseAPI();
const store = configureStore(api);

ReactDOM.render(
  <Root store={store} api={api} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
