import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { useCelebrityByRating } from '../hooks';
import { setCurrentField } from '../actions';
import { getCurrentField, getFields } from '../reducers';
import CelebrityCard from './Ratings/CelebrityCard';

export default function Home() {
  const fields = useSelector(getFields);
  const field = useSelector(getCurrentField);
  const dispatch = useDispatch();
  const { celebrities, isFetching, error, orderBy } = useCelebrityByRating(
    field
  );

  function handleFieldChange(event) {
    dispatch(setCurrentField(event.target.value));
  }

  function renderCelebrities(celebrities) {
    return celebrities.map((celebrity, idx) => (
      <CelebrityCard
        key={celebrity.id}
        index={idx}
        celebrity={celebrity}
        text={`Rating: ${Math.round(celebrity[orderBy]) || '-'}`}
      />
    ));
  }

  return (
    <div className="container">
      <div className="jumbotron jumbotron-fluid mb-3 mt-3">
        <div className="container text-center">
          <h1 className="display-4 text-primary font-logo">Vote Celebs</h1>
          <p
            className="lead text-primary font-logo"
            style={{ fontSize: '2rem' }}
          >
            Push your favorite celebrity to the top.
          </p>
          <Link className="btn btn-primary text-secondary" to={ROUTES.VOTE}>
            Vote
          </Link>
        </div>
      </div>
      <div className="jumbotron jumbotron-fluid mb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center text-center pb-4 pb-xl-0">
              <h1
                className="text-primary font-logo"
                style={{ fontSize: '2.5rem' }}
              >
                See Ratings &amp; Rankings
              </h1>
              <p
                className="lead text-primary font-logo"
                style={{ fontSize: '2rem' }}
              >
                Dive into the lists to see how popular celebs are.
              </p>
              <Link
                className="btn btn-primary text-secondary"
                to={ROUTES.RATINGS}
              >
                Dive
              </Link>
            </div>
            <div className="col-lg-6">
              <div className="row mb-2">
                <div className="col">
                  <h3 className="text-primary">Current Ratings</h3>
                </div>
                <div className="col-auto d-flex justify-content-end">
                  <form className="form-inline">
                    <select
                      className="form-control"
                      name="field"
                      onChange={handleFieldChange}
                      value={field}
                    >
                      {Object.keys(fields).map(key => (
                        <option key={key} value={key}>
                          {fields[key]}
                        </option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              {!isFetching && !celebrities.length ? (
                <div className="row">
                  <div className="col-12 mb-2 text-center">
                    <span className="text-primary">
                      Suggest more and Vote more to see someone here.
                    </span>
                  </div>
                </div>
              ) : (
                renderCelebrities(celebrities)
              )}
              {isFetching ? (
                <div className="row">
                  <div className="col-12 mb-2 text-center">
                    <span
                      className="spinner-grow spinner-grow-sm text-secodary"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                error && (
                  <div className="row">
                    <div className="col-12 mb-2 text-center">
                      <span className="text-danger">{error.message}</span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
