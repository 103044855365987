import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import Icon from './Icon';

const pages = [
  {
    route: ROUTES.ABOUT,
    title: 'About',
  },
  {
    route: ROUTES.CONTACT,
    title: 'Contact Us',
  },
  {
    route: ROUTES.TOS,
    title: 'Terms of Service',
  },
  {
    route: ROUTES.PP,
    title: 'Privacy Policy',
  },
  {
    route: ROUTES.CU,
    title: 'Cookie Use',
  },
];

const socialPages = [
  {
    url: 'https://www.facebook.com/reputelo',
    icon: 'facebook',
  },
  {
    url: 'https://www.instagram.com/reputelo_ig/',
    icon: 'instagram',
  },
  {
    url: 'https://twitter.com/reputelo',
    icon: 'twitter',
  },
  {
    url: 'https://www.youtube.com/channel/UC60cq9EkXDpKZNykDZmggEg',
    icon: 'youtube',
  },
];

export default function Footer() {
  return (
    <footer>
      <div className="container mt-3">
        <div className="row">
          <div className="col d-flex justify-content-start">
            <span className="float-left mr-2">Reputelo &copy; 2019</span>
            <ul className="list-inline">
              {pages.map(page => (
                <li key={page.route} className="list-inline-item">
                  <Link to={page.route}>{page.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-auto d-flex justify-content-end">
            <ul className="list-inline">
              {socialPages.map(page => (
                <li key={page.url} className="list-inline-item">
                  <a href={page.url} target="_blank" rel="noopener noreferrer">
                    <Icon icon={['fab', page.icon]} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
