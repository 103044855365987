import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as ROUTES from '../constants/routes';
import { fetchCelebrity } from '../actions';
import { getCelebrity, getFields } from '../reducers';
import { useRouter, useSearch } from '../hooks';
import CelebrityForm from './Celebrity/CelebrityForm';
import CelebrityImage from './Celebrity/CelebrityImage';
import withEmailVerification from './withEmailVerification';

function CelebritySuggest() {
  const [step, setStep] = useState(0);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const { setQuery, hits, loading, error } = useSearch('');
  useEffect(() => {
    setQuery(firstname + (lastname ? ' ' + lastname : ''));
  }, [firstname, lastname, setQuery]);

  const { match } = useRouter();
  const celebrityId = match.params.id ? match.params.id : null;

  const [loadingCelebrity, setLoadingCelebrity] = useState(!celebrityId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (celebrityId) {
      dispatch(fetchCelebrity(celebrityId)).then(() => {
        setLoadingCelebrity(false);
      });
    }
  }, [celebrityId, dispatch]);

  const celebrity = useSelector(state => getCelebrity(state, celebrityId));

  const fields = useSelector(getFields);

  if (celebrityId) {
    return (
      <>
        {loadingCelebrity ? (
          <div className="container">
            <div className="row mb-3">
              <div className="col-12 text-center">
                <div
                  className="spinner-grow spinner-grow-lg text-primary"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        ) : !celebrity ? (
          <div className="container">
            <div className="row mb-3">
              <div className="col-12 text-center">
                <span className="text-primary">Celebrity not found.</span>
              </div>
            </div>
          </div>
        ) : (
          <CelebrityForm
            initialCelebrity={celebrity}
            celebrityId={celebrityId}
          />
        )}
      </>
    );
  }

  const initialCelebrity = {
    firstname,
    lastname,
  };

  function handleSubmit(event) {
    event.preventDefault();
    setStep(1);
  }

  function handleSubmitSuccess() {
    setFirstname('');
    setLastname('');
    setStep(0);
  }

  if (step === 0) {
    return (
      <div className="container">
        <h1 className="text-dark">Celebrity Suggestion</h1>
        <form
          className="form-inline"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <label className="sr-only" htmlFor="firstname">
            Firstname (required)
          </label>
          <input
            className="form-control mb-2 mr-sm-2"
            id="firstname"
            type="text"
            name="firstname"
            placeholder="Firstname (required)"
            value={firstname}
            onChange={e => setFirstname(e.target.value)}
            required
          />
          <label className="sr-only" htmlFor="lastname">
            Lastname
          </label>
          <input
            className="form-control mb-2 mr-sm-2"
            id="lastname"
            type="text"
            name="lastname"
            placeholder="Lastname"
            value={lastname}
            onChange={e => setLastname(e.target.value)}
          />
          <button className="btn btn-primary mb-2" type="submit">
            Next
          </button>
        </form>

        <div
          className={`list-group already-suggested-container${
            loading || hits ? ' d-flex' : ' d-none'
          }`}
        >
          <div className="list-group-item">
            <span className="text-primary">Already suggested celebrities</span>
          </div>
          {loading ? (
            <div className="list-group-item d-flex justify-content-center">
              <div
                className="spinner-grow spinner-grow-sm text-primary"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            error && (
              <div className="list-group-item">
                <span className="text-danger">{error.message}</span>
              </div>
            )
          )}
          {hits && (
            <>
              <div className="search-results-container overflow-auto">
                {hits.map((celebrity, idx) => (
                  <SearchResultItem
                    key={celebrity.objectID}
                    celebrity={celebrity}
                    fields={fields}
                    last={idx === hits.length - 1}
                  />
                ))}
              </div>
              <div className="list-group-item p-1 pl-3 text-dark">
                <small>
                  Search by{' '}
                  <a
                    className="text-dark"
                    href="https://www.algolia.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Algolia
                  </a>
                </small>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <CelebrityForm
      title="Celebrity Suggestion"
      initialCelebrity={initialCelebrity}
      onSubmitSuccess={handleSubmitSuccess}
    />
  );
}

export default withEmailVerification(CelebritySuggest);
//export default CelebritySuggest;

function SearchResultItem({ celebrity, fields, last }) {
  const imageContainerRef = useRef(null);

  return (
    <div
      className={`list-group-item rounded-0${last ? ' border-bottom-0' : ''}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-3 px-0" ref={imageContainerRef}>
            <CelebrityImage
              celebrity={celebrity}
              container={imageContainerRef.current}
            />
          </div>
          <div className="col-9 pr-0">
            <div>
              {celebrity.firstname} {celebrity.lastname}
            </div>
            {celebrity.fields.length > 0 && (
              <div style={{ fontSize: '0.75rem' }}>
                {celebrity.fields.reduce(
                  (acc, field, idx) =>
                    acc +
                    fields[field] +
                    (idx !== celebrity.fields.length - 1 ? ', ' : ''),
                  ''
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function CelebritySuggestLink() {
  return (
    <div>
      <Link to={ROUTES.CELEBRITY_SUGGEST}>Suggest a celebrity</Link>
    </div>
  );
}

export function CelebritySuggestButton() {
  const history = useHistory();
  function handleClick() {
    history.push(ROUTES.CELEBRITY_SUGGEST);
  }

  return (
    <form className="form-inline">
      <button className="btn btn-primary" type="button" onClick={handleClick}>
        Suggest a new celebrity
      </button>
    </form>
  );
}
